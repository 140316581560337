// Leaves.js
import React from 'react';

const Leaves = ({ leaves, shouldShowLeaf }) => {
  return (
    <>
      {leaves.map((leafNum) => (
        shouldShowLeaf(leafNum) && (
          <div
            key={`l${leafNum}`}
            id={`l${leafNum}`}
            style={{ display: 'block' }}
            sname="true"
            className={`leaf leaf${(leafNum % 3) + 1}`}
          />
        )
      ))}
    </>
  );
};

export default Leaves;