// MainBranch.js
import React from 'react';
import InnerBranch from './InnerBranch';
import Flower from './Flower';
import Leaves from './Leaves';

function MainBranch({
  id,
  direction,
  branchNum,
  leaves,
  flowerId,
  innerBranches,
  shouldShowLeaf,
  shouldShowFlower,
  shouldShowBranch,
  getFlowerColor
}) {
  return (
    <div id={id} className={`branch branch${branchNum} ${direction}`}>
      <Leaves
        leaves={leaves}
        shouldShowLeaf={shouldShowLeaf}/>

      {shouldShowFlower(flowerId) && <Flower flowerId={flowerId} color={getFlowerColor(flowerId)} />}

      {innerBranches?.map((branch) => (
        shouldShowBranch(branch.id) && (
          <InnerBranch
            key={branch.id}
            {...branch}
            shouldShowLeaf={shouldShowLeaf}
            shouldShowFlower={shouldShowFlower}
            shouldShowBranch={shouldShowBranch}
            getFlowerColor={getFlowerColor}
          />
        )
      ))}
    </div>
  );
}

export default MainBranch;