// Table to display the annotated transcript
import React from 'react';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Paper } from "@mui/material";
const SegmentsTable = ({ segments }) => {
  return (
    <div style={{ marginTop: "50px", marginBottom: "50px" }}>
      <TableContainer component={Paper} style={{ maxHeight: 800, overflow: 'scroll' }}>
        <Table aria-label="segments table">
          <TableHead>
            <TableRow>
              <TableCell style={{ padding: '5px' }}>Statement ID</TableCell>
              <TableCell style={{ padding: '5px' }}>Speaker ID</TableCell>
              <TableCell style={{ padding: '5px' }}>Speaker type</TableCell>
              <TableCell style={{ padding: '5px' }}>Chat or Speech</TableCell>
              <TableCell style={{ padding: '5px' }}>Start</TableCell>
              <TableCell style={{ padding: '5px' }}>End</TableCell>
              <TableCell style={{ padding: '5px' }}>Text</TableCell>
              <TableCell style={{ padding: '5px' }}>Talk move category</TableCell>
              <TableCell style={{ padding: '5px' }}>Talk move Type</TableCell>
              <TableCell style={{ padding: '5px' }}>Classification probability</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {segments.slice(0, 20).map((segment, index) => (
              <React.Fragment key={index}>
                {segment.map((item, subIndex) => (
                  <TableRow key={subIndex}>
                    <TableCell style={{ padding: '5px' }}>{item.id}</TableCell>
                    <TableCell style={{ padding: '5px' }}>{item.participantId}</TableCell>
                    <TableCell style={{ padding: '5px' }}>{item.speakerType}</TableCell>
                    <TableCell style={{ padding: '5px' }}>{item.type}</TableCell>
                    <TableCell style={{ padding: '5px' }}>{item.start}</TableCell>
                    <TableCell style={{ padding: '5px' }}>{item.end}</TableCell>
                    <TableCell style={{ padding: '5px' }}>{item.text}</TableCell>
                    <TableCell style={{ padding: '5px' }}>
                      {item.annotations && item.annotations.find(annotation => annotation.annotationType === "talk_move") ? 
                        item.annotations.find(annotation => annotation.annotationType === "talk_move").category : null}
                    </TableCell>
                    <TableCell style={{ padding: '5px' }}>
                      {item.annotations && item.annotations.find(annotation => annotation.annotationType === "talk_move") ? 
                        item.annotations.find(annotation => annotation.annotationType === "talk_move").values[0] : null}
                    </TableCell>
                    <TableCell style={{ padding: '5px' }}>
                    {(() => {
                      const talkMoveAnnotation = item.annotations && item.annotations.find(annotation => annotation.annotationType === "talk_move");
                      if (talkMoveAnnotation) {
                        const probability = talkMoveAnnotation.probability;
                        return Array.isArray(probability)
                          ? Math.max(...probability.map(Number)).toFixed(3)
                          : parseFloat(probability).toFixed(3);
                      }
                      return null;
                    })()}
                    </TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SegmentsTable;