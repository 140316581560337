export const treeStructure = [
  {
    id: 'b1',
    direction: 'left',
    branchNum: 1,
    leaves: [4, 5, 6],
    flowerId: 'f5',
    innerBranches: [
      {
        id: 'b2',
        direction: 'left',
        type: 'branch-inner1',
        leaves: [7, 8, 9],
        flowerId: 'f2'
      },
      {
        id: 'b3',
        direction: 'left',
        type: 'branch-inner2',
        leaves: [10, 11, 12],
        flowerId: 'f3'
      },
      {
        id: 'b4',
        direction: 'left',
        type: 'branch-inner3',
        leaves: [13, 14],
        flowerId: 'f4'
      }
    ]
  },
  {
    id: 'b5',
    direction: 'right',
    branchNum: 2,
    leaves: [15, 16, 17],
    flowerId: 'f10',
    innerBranches: [
      {
        id: 'b6',
        direction: 'left',
        type: 'branch-inner1',
        leaves: [18, 19, 20],
        flowerId: 'f6'
      },
      {
        id: 'b7',
        direction: 'right',
        type: 'branch-inner2',
        leaves: [21, 22, 23],
        flowerId: 'f7'
      },
      {
        id: 'b8',
        direction: 'right',
        type: 'branch-inner3',
        leaves: [24, 25, 26],
        flowerId: 'f9',
        subBranch: {
          id: 'b9',
          direction: 'left',
          type: 'branch-subinner',
          leaves: [27],
          flowerId: 'f8'
        }
      }
    ]
  },
  {
    id: 'b10',
    direction: 'left',
    branchNum: 3,
    leaves: [28, 29, 30],
    flowerId: 'f13',
    innerBranches: [
      {
        id: 'b11',
        direction: 'right',
        type: 'branch-inner1',
        leaves: [31, 32, 33],
        flowerId: 'f11'
      },
      {
        id: 'b12',
        direction: 'left',
        type: 'branch-inner2',
        leaves: [34, 35, 36],
        flowerId: 'f12'
      }
    ]
  },
  {
    id: 'b13',
    direction: 'right',
    branchNum: 4,
    leaves: [37, 38, 39],
    flowerId: 'f16',
    innerBranches: [
      {
        id: 'b14',
        direction: 'left',
        type: 'branch-inner1',
        leaves: [40, 41, 42],
        flowerId: 'f14'
      },
      {
        id: 'b15',
        direction: 'right',
        type: 'branch-inner2',
        leaves: [43, 44, 45],
        flowerId: 'f15'
      }
    ]
  },
  {
    id: 'b16',
    direction: 'left',
    branchNum: 5,
    leaves: [46, 47, 48],
    flowerId: 'f19',
    innerBranches: [
      {
        id: 'b17',
        direction: 'right',
        type: 'branch-inner1',
        leaves: [49, 50, 51],
        flowerId: 'f17'
      },
      {
        id: 'b18',
        direction: 'left',
        type: 'branch-inner2',
        leaves: [52, 53, 54],
        flowerId: 'f18'
      }
    ]
  }
];


export const MAX_BRANCHES = 18;
export const MAX_LEAVES = 54;
export const MAX_FLOWERS = 19;

// In constants/constants.js
export const typeFlowerColors = {
  making_claim: 'blueflower',
  relating: 'yellowflower',
  providing_evidence: 'redflower',
  revoicing: 'blueflower',
  reasoning: 'redflower',
  students_relating: 'yellowflower'
};
