// Flower.js
import React from 'react';

const Flower = ({ flowerId, color }) => {
  return (

    <div
      id={flowerId}
      style={{ display: 'block' }}
      className={`flower petal5 flower2 ${color}`}
    >
      <div style={{ display: 'block' }} className="petal">
        <div style={{ display: 'block' }} className="petal">
          <div style={{ display: 'block' }} className="petal"></div>
        </div>
      </div>
    </div>
  );
};

export default Flower;