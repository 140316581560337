// treeUtils.js
export const getTalkMoveFlowerThresholds = (speakerType = 'student', challengeRatio = 1.0, typeOrCategory = 'category') => {
  return typeOrCategory === 'category'
    ? getTalkMoveCategoryFlowerThresholds(speakerType, challengeRatio)
    : getTalkMoveTypeFlowerThresholds(speakerType, challengeRatio);
};

export const getTalkMoveTypeFlowerThresholds = (speakerType = 'student', challengeRatio = 1.0) => {
  let flowerThresholdsByType = {};

  if (speakerType === 'student') {
    flowerThresholdsByType = {
      making_claim: 3,
      relating: 1,
      providing_evidence: 1
    };
  } else if (speakerType === 'tutor') {
    flowerThresholdsByType = {
      revoicing: 1,
      reasoning: 1,
      students_relating: 1
    };
  } else {
    flowerThresholdsByType = {
      making_claim: 2,
      relating: 1,
      providing_evidence: 1
    };
  }

  // Multiply the thresholds by the challengeRatio
  Object.keys(flowerThresholdsByType).forEach(talkType => {
    let newThreshold = Math.floor(flowerThresholdsByType[talkType] * challengeRatio);
    if (newThreshold === 0) {
      newThreshold = 1;
    }
    flowerThresholdsByType[talkType] = newThreshold;
  });
  return flowerThresholdsByType;
};

export const getTalkMoveCategoryFlowerThresholds = (speakerType = 'student', challengeRatio = 1.0) => {
  let flowerThresholdsByCategory = {};

  if (speakerType === 'student') {
    flowerThresholdsByCategory = {
      content_knowledge: 3,
      learning_community: 2,
      rigorous_thinking: 1
    };
  } else if (speakerType === 'tutor') {
    flowerThresholdsByCategory = {
      content_knowledge: 4,
      learning_community: 3,
      rigorous_thinking: 2
    };
  } else {
    flowerThresholdsByCategory = {
      content_knowledge: 3,
      learning_community: 2,
      rigorous_thinking: 1
    };
  }

  // Multiply the thresholds by the challengeRatio will be used in future (challengeRatio)
  Object.keys(flowerThresholdsByCategory).forEach(category => {
    let newThreshold = Math.floor(flowerThresholdsByCategory[category] * challengeRatio);
    if (newThreshold === 0) {
      newThreshold = 1;
    }
    flowerThresholdsByCategory[category] = newThreshold;
  });

  return flowerThresholdsByCategory;
};

export const getUtteranceLeafThreshold = (speakerType = 'student', challengeRatio = 1.0) => {
  let leafThreshold = 4;

  if (speakerType === 'student') {
    leafThreshold = 4;
  } else if (speakerType === 'tutor') {
    leafThreshold = 5;
  } else {
    leafThreshold = 4;
  }

  // Multiply the threshold by the challengeRatio
  leafThreshold = Math.floor(leafThreshold * challengeRatio);
  if (leafThreshold === 0) {       
    leafThreshold = 1;
  }

  return leafThreshold;
};

export const isActiveTMGroup = (tmGroup, typeOrCategory = 'category') => {
  if (typeOrCategory === 'category') {
    return ['content_knowledge', 'learning_community', 'rigorous_thinking'].includes(tmGroup);
  } else {
    return ['making_claim', 'relating', 'providing_evidence', 'revoicing', 'reasoning', 'students_relating'].includes(tmGroup);
  }
};