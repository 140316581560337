import React, { useState, useEffect, useCallback } from 'react';
import './Tree.css';
import Flower from './components/Flower';
import MainBranch from './components/MainBranch';
import { MAX_BRANCHES, MAX_FLOWERS, MAX_LEAVES, treeStructure } from './constants/constants';
import { 
  getTalkMoveTypeFlowerThresholds,
  getUtteranceLeafThreshold, 
  isActiveTMGroup 
} from './utils/treeUtils';
import { typeFlowerColors } from './constants/constants';
// Import the timezone components
import { SessionTimeInfo } from './TimezoneUtility';

// Format mapping for talk move names
const formatTalkMoveNames = {
  making_claim: 'Making a Claim',
  relating: 'Relating to Another Student',
  providing_evidence: 'Providing Evidence',
  revoicing: 'Revoicing',
  reasoning: 'Press for Reasoning',
  students_relating: 'Getting Students to Relate'
};

export function Tree({ containerRef, processTranscriptData, sessionId, yearInputType, region, retrieveSagaUserRegion }) {
  const [sessionMetadata, setSessionMetadata] = useState(null);
  const [currentSessionId, setCurrentSessionId] = useState(null);
  const [tutorId, setTutorId] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [treeFullnessInfo, setTreeFullnessInfo] = useState(null);
  const [treeData, setTreeData] = useState({
    student: { statements: [], processedData: {}, flowerAllocation: {}, treeFullnessInfo: null }
  }); 
  const formatTime = (timeInSeconds) => {
    if (timeInSeconds == null || isNaN(timeInSeconds)) return '00:00';
    
    // Convert seconds to minutes; rounding to nearest minute
    const minutes = Math.round(timeInSeconds / 60);
    return `${minutes}`;
  };

  const renderTreeStats = (data, speakerType) => {
    const thresholds = getTalkMoveTypeFlowerThresholds(speakerType);
    return (
      <div className="stats-container">
        <table className="stats-table">
          <thead>
            <tr>
              <th>Talk Move</th>
              <th>Count</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(thresholds).map((key) => (
              <tr key={key}>
                <td>
                  {['making_claim', 'relating', 'providing_evidence'].includes(key) && (
                    <span className="flower-icon">
                      <Flower flowerId={`stat-${key}`} color={typeFlowerColors[key]} />
                    </span>
                  )}
                  {formatTalkMoveNames[key] || key}
                </td>
                <td>{data.processedData[key] || 0}</td>
              </tr>
            ))}
            <tr >
  <td>
    <span className='leaffordisplay'></span>Student Utterances
  </td>
  <td>{data.statements.length}</td>
</tr>

          </tbody>
        </table>
      </div>
    );
  };

  useEffect(() => {
    if (sessionId) {
      setCurrentSessionId(sessionId);
      setTutorId(null);
      setIsLoaded(false); 
      setSessionMetadata(null); 
    }
  }, [sessionId]);

  const processTreeData = useCallback(async (speakerType) => {
    if (!currentSessionId) {
      return {
        statements: [],
        processedData: {},
        flowerAllocation: {},
        sessionMetadata: null,
        treeFullnessInfo: null
      };
    }
    
    const inputType = yearInputType;
    
    try {
      const response = await processTranscriptData(currentSessionId, inputType);
      if (!response) return null;
      
      const { processedData: data, sessionMetadata } = response;
      
      if (data && Array.isArray(data)) {
        // Filter statements by speaker type
        const filteredStatements = data.filter(item => 
          item.speakerType.toLowerCase() === speakerType.toLowerCase()
        );
  
        // Get thresholds based on view mode and speaker type
        const thresholds = getTalkMoveTypeFlowerThresholds(speakerType);
          
        const processedDataCounts = Object.keys(thresholds).reduce((acc, key) => ({
          ...acc,
          [key]: 0
        }), {});
  
        // Dynamically create category/type count and flower allocation
        const flowerAllocation = {};
        let currentFlowerIndex = 1;
        let currentBranchIndex = 1;
        let currentLeafIndex = 1;
        let utteranceCount = 0;
        const flowers = [];
        const branches = [];
        const leaves = [];
        let treeFullnessInfo = null;
        // Count categories/types
        for (const statement of filteredStatements) {
          // Get the talk move type
          const tmGroup = statement.talkMoveType;

          if (isActiveTMGroup(tmGroup, 'type')) {
            processedDataCounts[tmGroup] += 1;
            
            // Check if we've reached the threshold for this talk move
            if (processedDataCounts[tmGroup] % thresholds[tmGroup] === 0) {
              // Add a flower if we haven't exceeded the max
              if (flowers.length < MAX_FLOWERS) {
                const flowerId = `f${currentFlowerIndex++}`;
                flowers.push({ id: flowerId, type: tmGroup });
                
                // Add a new branch if needed
                if (branches.length < MAX_BRANCHES) {
                  const branchId = `b${currentBranchIndex++}`;
                  branches.push(branchId);
                }
                if (
                  flowers.length === MAX_FLOWERS && 
                  branches.length === MAX_BRANCHES && 
                  !treeFullnessInfo
                ) {
                  const sessionStartTime = sessionMetadata.startTime;
                  const elapsedTimeInSeconds = Math.floor(statement.start);
                  // Calculate total session time in minutes
                  const sessionEndTime = sessionMetadata.endTime;
                  const totalSessionTimeInSeconds = Math.floor((sessionEndTime - sessionStartTime) / 1000);
                  treeFullnessInfo = {
                    elapsedTime: elapsedTimeInSeconds,
                    totalSessionTime: totalSessionTimeInSeconds,
                    statementText: statement.text || 'No statement text available'
                  };
                }
              }
            }
          }

          // Handle utterance counts and leaves
          utteranceCount++;
          const leafThreshold = getUtteranceLeafThreshold(speakerType);
          if (utteranceCount % leafThreshold === 0 && leaves.length < MAX_LEAVES) {
            const leafId = `l${currentLeafIndex++}`;
            leaves.push(leafId);
          }
        } 
        Object.keys(thresholds).forEach(tmGroup => {
          flowerAllocation[tmGroup] = {
            flowers: flowers.filter(f => f.type === tmGroup).map(f => f.id),
            branches,
            leaves,
            count: processedDataCounts[tmGroup],
            threshold: thresholds[tmGroup],
            expectedFlowers: Math.floor(processedDataCounts[tmGroup] / thresholds[tmGroup])
          };
        }); 
        return {
          statements: filteredStatements,
          processedData: processedDataCounts,
          flowerAllocation,
          chronologicalFlowers: flowers,
          sessionMetadata,
          treeFullnessInfo
        };
      } else {
        return {
          statements: [],
          processedData: {},
          flowerAllocation: {},
          sessionMetadata: null,
          treeFullnessInfo: null

        };
      }
    } catch (error) {
      return {
        statements: [],
        processedData: {},
        flowerAllocation: {},
        sessionMetadata: null,
        treeFullnessInfo: null
      };
    }
  }, [processTranscriptData, currentSessionId, yearInputType]);

  useEffect(() => {
    if (sessionMetadata?.participants) {
      const tutor = sessionMetadata.participants.find(p => p.type === 'tutor');
      if (tutor?.id) {
        setTutorId(tutor.id);
      }
    }
  }, [sessionMetadata]);

  useEffect(() => {
    const fetchData = async () => {
      if (!currentSessionId) return;
      
      // Only fetch student tree data
      const studentTreeData = await processTreeData('student');
      
      if (studentTreeData) {
        setTreeData(prevData => ({
          ...prevData,
          student: {
            ...studentTreeData,
            treeFullnessInfo: studentTreeData.treeFullnessInfo
          }
        }));
        
        // Set session metadata and update tutorId
        const metadata = studentTreeData.sessionMetadata;
        setSessionMetadata(metadata);
        
        if (studentTreeData.treeFullnessInfo) {
          setTreeFullnessInfo(studentTreeData.treeFullnessInfo);
        }
        // Get tutor ID from metadata and set it
        const tutor = metadata?.participants?.find(p => p.type === 'tutor');
        if (tutor?.id) {
          setTutorId(tutor.id);
        }
        
        setIsLoaded(true);
      }
    };
  
    fetchData();
  }, [currentSessionId, processTreeData]);

  const createTreeRenderHelpers = (treeData, speakerType) => {
    const getFlowerColor = (flowerId) => {
      const flower = treeData.chronologicalFlowers?.find(f => f.id === flowerId);
      if (!flower) return 'whiteflower';
      return typeFlowerColors[flower.type] || 'whiteflower';
    };

    const calculateBranchLimit = () => {
      const leafThreshold = getUtteranceLeafThreshold(speakerType);
      const totalStatementsCount = treeData.statements.length; 
      // Initialize leaf tracking
      let total_leaf_count = 0;
      let branch_leaf_count = 0;
      const max_leaves_per_branch = 3;
      let leaf_branch_count = 0;
      // Count leaves and determine branches needed
      for (let i = 1; i <= totalStatementsCount; i++) {
        if (branch_leaf_count > max_leaves_per_branch) {
          leaf_branch_count++;
          branch_leaf_count = 1;
          if (leaf_branch_count > MAX_BRANCHES) {
            break;
          }
        }
        if (i % leafThreshold === 0) {
          if (total_leaf_count === MAX_LEAVES) {
            break;
          }
          total_leaf_count++;
          branch_leaf_count++;
        }
      }
      // Calculate branches based on leaves
      const branchesFromLeaves = leaf_branch_count;
      // Calculate branches based on flowers
      const totalFlowers = Object.values(treeData.flowerAllocation)
        .reduce((sum, allocation) => sum + allocation.flowers.length, 0);
      // Maximum of the two calculations for enough branches
      const requiredBranches = Math.max(branchesFromLeaves, totalFlowers);
      // Don't exceed MAX_BRANCHES
      return Math.min(requiredBranches, MAX_BRANCHES);
    };
    
    const shouldShowBranch = (branchId) => {
      const branchLimit = calculateBranchLimit();
      if (!branchLimit) return false;
      const currentBranchNum = parseInt(branchId.slice(1));
      return currentBranchNum <= branchLimit;
    };
  
    const shouldShowFlower = (flowerId) => {
      for (const allocation of Object.values(treeData.flowerAllocation)) {
        if (allocation.flowers.includes(flowerId)) return true;
      }
      return false;
    };
  
    const shouldShowLeaf = (leafNum) => {
      if (leafNum > MAX_LEAVES) {
        return false;
      }
      const leafThreshold = getUtteranceLeafThreshold(speakerType);
      const statementsNeeded = leafNum * leafThreshold;
      return treeData.statements.length >= statementsNeeded;
    };
    
    return {
      getFlowerColor,
      shouldShowBranch,
      shouldShowLeaf,
      shouldShowFlower,
      processedData: treeData.processedData,
      statements: treeData.statements
    };
  };

  // Create render helpers for student only
  const studentTreeHelpers = createTreeRenderHelpers(treeData.student, 'student');
  const NoDataMessage = () => (
    <div className="no-data-message">
      <p>No Talk Moves generated</p>
    </div>
  ); 
  
  if (!isLoaded) {
    return (
      <div className="loading-container">
        <p>Loading tree...</p>
      </div>
    );
  }  

  const renderTreeFullnessInfo = () => {
    const treeFullnessInfo = treeData.student.treeFullnessInfo;
    
    if (!treeFullnessInfo) {
      return null;
    }
  
    return (
      <div className="tree-fullness-info">
        <p style={{ margin: 0, fontWeight:'bold', lineHeight: '1', }}>
          The talk tree filled at {formatTime(treeFullnessInfo.elapsedTime)} of {formatTime(treeFullnessInfo.totalSessionTime)} minutes.
        </p>
      </div>
    );
  };
    
  
  const hasStudentData = treeData.student.statements.length > 0;
  
  return (
    <div className="tree-container">
      {/* Student Tree Section */}
      <div className="tree-visualization">
       
        {hasStudentData ? (
          <div className="tree-display">
            <div ref={containerRef} className="background">
              <div className="slope"></div>
              <div className="tree">
                {[1, 2, 3].map(leafNum => (
                  studentTreeHelpers.shouldShowLeaf(leafNum) && (
                    <div
                      key={`student-leaf-${leafNum}`}
                      id={`student-leaf-${leafNum}`}
                      style={{ display: 'block' }}
                      className={`leaf leaf${leafNum}`}
                    ></div>
                  )
                ))}
                {studentTreeHelpers.shouldShowFlower('f1') &&
                  <Flower flowerId={'f1'} color={studentTreeHelpers.getFlowerColor('f1')} />}
                {treeStructure.map((branch) => (
                  studentTreeHelpers.shouldShowBranch(branch.id) && (
                    <MainBranch
                      key={`student-${branch.id}`}
                      {...branch}
                      shouldShowLeaf={studentTreeHelpers.shouldShowLeaf}
                      shouldShowFlower={studentTreeHelpers.shouldShowFlower}
                      shouldShowBranch={studentTreeHelpers.shouldShowBranch}
                      getFlowerColor={studentTreeHelpers.getFlowerColor}
                    />
                  )
                ))}
              </div>
            </div>
          </div>
        ) : (
          <NoDataMessage />
        )}
      </div>

     {/* Session Information Section */}
{sessionMetadata && (
  <div className="session-data">
    {/* Talk Move Statistics */}
    <div className="stats-section">
      {renderTreeStats(treeData.student, "student")}
    </div>

    {/* Session Information */}
    <div className="session-metadata" style={{height:"140px"}}>
      <h2 className="section-title">{sessionMetadata.title}</h2>
      <div className="info-content">
        <p>
          <strong>Students:</strong>{" "}
          {sessionMetadata?.activeParticipants
            //?.map((participant) => participant.name)
            ?.map((participant) => participant.id)
            .sort((a, b) => a.localeCompare(b))
            .join(", ")}
        </p>
        {/* Session Time Info */}
        <SessionTimeInfo
          sessionMetadata={sessionMetadata}
          region={region}
          retrieveSagaUserRegion={retrieveSagaUserRegion}
        />
      </div>
    </div>
    {renderTreeFullnessInfo()}
  </div>
)}

    </div>
  );
}

export default Tree;