

/**
 * LEVI-HAT Data UI Dashboard (React component)
 *
 * This component displays a dashboard with various features for retrieving 
 * and viewing tutoring session source data and derived data.
 * 
 * The component currently provides the following features:
 *   1. Number of sessions by day
 *   2. Annotated transcript for a given session ID
 *   3. Tutor averages viewer
 *   4. LLM response generator
 *   5. Analytics for talk moves
 *
 */

import React, { useCallback, useEffect, useRef, useState} from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import "./styles.css";
import { Pie, Bar } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import { Button, TextField, Grid, CircularProgress,  IconButton } from "@mui/material";
import exportFromJSON from "export-from-json";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import TutorChart from './TutorAveragesChart';
import { DataGrid } from '@mui/x-data-grid';
import { Tooltip as MaterialTooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { useNavigate } from 'react-router-dom';
import userpool from './userpool';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";


// pie charts
import { Card, CardContent, Typography } from '@mui/material';
import { ArcElement } from 'chart.js';
import AccordionComponent from "./components/AccordionComponent";
import HorizontalNavbar from "./components/HorizontalNavbar";
import SegmentsTable from "./components/SegmentsTable";
import SessionTrees from "./SessionTrees";
import { formatTimestampInRegionTimezone, getTimestampRangeForDate, getTimeZoneForRegion } from "./Tree/TimezoneUtility";
ChartJS.register(
  LineElement,
  BarElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  Filler,
  annotationPlugin,
  ArcElement, 
  Tooltip,
  Legend,
  ChartDataLabels,
);

const useStyles = makeStyles({
  rowIconStyle: {
    minWidth: 50,
    minHeight: 50
  },
  tableRowStyle: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "darkGrey"
    }
  },
  rowSelectionCheckboxStyle: {
    // opacity: "calc(var(--oneRowSelected))",
    opacity: 0,
    "$tableRowStyle:hover &": {
      opacity: 1
    }
  }
});

function Dashboard() {

  const navigate = useNavigate();

  
  const [availablePeriods, setAvailablePeriods] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState('');
  //states used to manage the data returned from the API
  const [data, setData] = useState([]);
  const [transcriptData, setTranscriptData] = useState([]);
  const [averagesData, setAveragesData] = useState([]);


  //User input states
  const [selectedDate, setSelectedDate] = useState("");
  const [sessionLoading, setSessionLoading] = useState(false);
  const [sessionDownload, setSessionDownload] = useState(false);
  const [averagesLoading, setAveragesLoading] = useState(false);
  const [transcriptLoading, setTranscriptLoading] = useState(false);
  const [transcriptDownload, setTranscriptDownload] = useState(false);
  const [authToken, setAuthToken] = useState("");
  const [transcriptSessionID, setTranscriptSessionID] = useState("");
  const [showSession, setShowSession] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [sessionFlag, setSessionFlag] = useState(false);
  const [transcriptFlag, setTranscriptFlag] = useState(false);
  const [showSessionGraph, setShowSessionGraph] = useState(false);
  const [showTranscriptTable, setShowTranscriptTable] = useState(false);
  const [showTutorAverages, setShowTutorAverages] = useState(false);
  const [regionList, setRegionList] = useState([]);
  const [tutorList, setTutorList] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedMetric, setSelectedMetric] = useState("");
  const [selectedTutorIds, setSelectedTutorIds] = useState([]); 
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [chartMetric, setChartMetric ] = useState({});
  const [activeVisualization, setActiveVisualization] = useState('transcript');


  //LLM prompt summary states
  const [showLLM, setShowLLM] = useState(false);
  const [showLLMSummary, setShowLLMSummary] = useState(false);
  const [promptData, setPromptData] = useState('You are an experienced tutor of middle school mathematics. We want to emphasize equitable student engagement. Given the following transcript that identifies tutor and student statements in a recent tutoring session, provide insights into which students were or were not engaged and provide ideas on how the tutor might improve equitable engagement. Provide response in a JSON format of: { \"engagementFeedback\": <feedback> \"engagementAdvice\": <advice> }\n\nTranscript:\n<<transcript>>');
  const [llmSessionID, setLLMSessionID] = useState('');
  const [llmLoading, setLLMLoading] = useState(false);
  const [llmSummary, setLLMSummary] = useState('');
  const [selectedModel, setSelectedModel] = useState('gpt-4o-mini');
const [activeMenu, setActiveMenu] = useState('Session-level Visualizations');

  // Analytics states
  const [showAnalytics, setShowAnalytics] = useState(false);
  const [tutorTalkMoves, setTutorTalkMoves] = useState([]);
  const [studentTalkMoves, setStudentTalkMoves] = useState([]);
  const talkMovesLabels = ['Content Knowledge', 'Learning Community', 'Rigorous Thinking'];
  const [analyticsLoading, setAnalyticsLoading] = useState(false);
  const [showGraphs, setShowGraphs] = useState(false);
  const [sessionOverview, setSessionOverview] = useState('');
  const [mathTopicsCovered, setMathTopicsCovered] = useState(''); 
  const [feedbackSessionHighlights, setFeedbackSessionHighlights] = useState('');
  const [studentParticipation, setStudentParticipation] = useState([]);
  const [analyticstSessionID, setAnalyticsSessionID] = useState('');
  const [participationMetric, setParticipationMetric] = useState('sentences'); // default value = sentences
  const [selectedTutorId, setSelectedTutorId] = useState('');
  const [previousSessionIds, setPreviousSessionIds] = useState([]);
  // input_type for pipeline identifier
  const [inputType, setInputType] = useState('2024-fall-standard');
  const [schoolYear, setSchoolYear] = useState('2024-2025');

  // Mapping of input types to school years
  const inputTypeToSchoolYearMap = {
    '2024-fall-standard': '2024-2025',
    '2023-fall-standard': '2023-2024',
    '2025-TeachFX-sample': 'TeachFX Sample Sessions'
  };

  // error handling states
  const [notFoundError, setNotFoundError] = useState(""); // sessiondata not found
  const [ analyticsNotFoundError, setAnalyticsNotFoundError] = useState(""); // analytics session not found
  const [analyticsFlag, setAnalyticsFlag] = useState(false); // analytics service - session field empty before clicking view
  const [sessionDataCache, setSessionDataCache] = useState({
    transcript: null,
    analytics: null
  });
  // Convert the selected date to the desired format
  const dateString = selectedDate;
  const parts = dateString.split("-");
  const rearrangedDate = `${parts[1]}-${parts[2]}-${parts[0]}`;

  const formattedDate = new Date().toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  const formattedEndDate = formattedDate.split("/").join("-");

  //Column headers for the tutor list table
  const TutorColumns = [
    { field: 'userId', headerName: ' Tutor ID', width: 90 },
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'siteName', headerName: 'Site Name', width: 150 },
    { field: 'siteId', headerName: 'Site ID', width: 150 },
  ];
 
  // Minimum allowed date for averages viewer
  const minStartDate = '2023-09-01'; 

  // functions to handle user input states
  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const getDayKey = (timestamp) => {
    const date = new Date(timestamp);
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  };
  
  const fetchCurrentAndPreviousSessions = async (selectedPeriodId, tutorId) => {
    if (!selectedPeriodId || !tutorId) {
      return { currentSession: null, previousSessions: [] };
    }
  
    try {
      await isTokenExpired();
      const authToken = sessionStorage.getItem("access_token");
  
      const selectedPeriod = availablePeriods.find((p) => p.id === selectedPeriodId);
      if (!selectedPeriod) {
        return { currentSession: null, previousSessions: [] };
      }
  
      const selectedDate = new Date(selectedPeriod.startTime);
      const selectedSessionId = selectedPeriod.sessionId;
      
      // Get the selected time for time matching
      const selectedHour = selectedDate.getHours();
      const selectedMinute = selectedDate.getMinutes();
      const selectedTimeInMinutes = (selectedHour * 60) + selectedMinute;
      
      // Define the acceptable time window (±20 minutes)
      const timeWindowMinutes = 60;
  
      // Calculate date range for the past week
      const endTime = selectedPeriod.startTime;
      const numPriorDays = 20;
      const numSessionsDisplayed = 6;
      const nDaysAgo = new Date(selectedDate);
      nDaysAgo.setDate(nDaysAgo.getDate() - numPriorDays); // Get N prior days before the selected day
      const startTime = nDaysAgo.getTime();
  
      const url = `https://api.hatproject.org/v1/analysis/sessiondata?getByTutorHour&endTime=${endTime}&startTime=${startTime}&tutorId=${tutorId}`;
  
      const response = await axios.get(url, {
        headers: {
          Authorization: authToken,
        },
        timeout: 15000,
      });
  
      let allSessions = [];
      if (response.data && Array.isArray(response.data)) {
        // log the number of sessions retrieved
        response.data.forEach((hourEntry) => {
          if (hourEntry && Array.isArray(hourEntry.sessions)) {
            allSessions = [...allSessions, ...hourEntry.sessions];
          }
        });
      }
      
      // Create a Set of session IDs to exclude duplicates
      const excludedSessionIds = new Set();
      excludedSessionIds.add(selectedSessionId);
      
      // Generate dates for the past N days
      const weekDates = [];
      for (let i = 0; i < numPriorDays; i++) {
        const date = new Date(selectedDate);
        date.setDate(date.getDate() - i);
        weekDates.push({
          date: date,
          dayKey: getDayKey(date.getTime()),
          hasSessions: i === 0 // Current day always has session
        });
      }
      
      // Create a map to store the best session for each day
      const dailyBestSessions = new Map();
      
      // Process all retrieved sessions
      allSessions.forEach(session => {
        if (!session.startTime || !session.sessionId) return;
        
        // Skip the current session
        if (session.sessionId === selectedSessionId) return;
        
        const sessionDate = new Date(session.startTime);
        const sessionDayKey = getDayKey(session.startTime);
        
        // Skip future sessions
        if (sessionDate.getTime() > selectedDate.getTime()) return;
        
        // Find which day of the week this session belongs to
        const weekdayIndex = weekDates.findIndex(day => day.dayKey === sessionDayKey);
        if (weekdayIndex === -1) return; // Not in our week range
        
        // Calculate time similarity
        const sessionHour = sessionDate.getHours();
        const sessionMinute = sessionDate.getMinutes();
        const sessionTimeInMinutes = (sessionHour * 60) + sessionMinute;
        
        // Calculate the circular time difference (handling day wrap-around)
        let timeDiff = Math.abs(sessionTimeInMinutes - selectedTimeInMinutes);
        if (timeDiff > 720) { // More than 12 hours difference
          timeDiff = 1440 - timeDiff; // 24 hours = 1440 minutes
        }
        // if the timeDiff is greater than timeWindowMinutes, go to next session
        if (timeDiff > timeWindowMinutes) return;
        
        // Store the time difference for comparison
        session.timeDifference = timeDiff;
        
        // Check if this is a better session for this day
        if (!dailyBestSessions.has(sessionDayKey) || 
            session.timeDifference < dailyBestSessions.get(sessionDayKey).timeDifference) {
          dailyBestSessions.set(sessionDayKey, session);
        }
      });
      
      // Create the prior sessions array with session data where available
      const priorSessions = [];
      
      // Add the current day's session
      priorSessions.push({
        sessionId: selectedPeriod.sessionId,
        title: selectedPeriod.title,
        startTime: selectedPeriod.startTime,
        students: selectedPeriod.students || [],
        dayKey: weekDates[0].dayKey,
        date: weekDates[0].date
      });
      
      // Add sessions, if any, for the previous N days;
      for (let i = 1; i < numPriorDays; i++) {
        const dayData = weekDates[i];
        const bestSession = dailyBestSessions.get(dayData.dayKey);
        
        if (bestSession) {
          priorSessions.push({
            sessionId: bestSession.sessionId,
            title: bestSession.title || 'No Title',
            startTime: new Date(bestSession.startTime).getTime(),
            dayKey: dayData.dayKey,
            date: dayData.date,
            timeDifference: bestSession.timeDifference,
          });
        } 
      }
      const result = {
        currentSession: priorSessions[0],
        previousSessions: priorSessions.slice(1, numSessionsDisplayed+1) // Ensure exactly 6 previous sessions
      };   
      return result;
    } catch (error) {
      console.error('Error fetching current and previous sessions:', error);
      return { currentSession: null, previousSessions: [] };
    }
  };

const handlePeriodChange = (event) => {
  const selectedValue = event.target.value;
  const numPriorDays = 20;
  setSelectedPeriod(selectedValue);
  
  if (selectedValue) {
    const selectedPeriodObj = availablePeriods.find(p => p.id === selectedValue);
    if (selectedPeriodObj) {
      const sessionId = selectedPeriodObj.sessionId;
      
      // Set the analytics session ID for the current session
      setAnalyticsSessionID(sessionId);
      setTranscriptSessionID(sessionId);
      
      // Fetch the current and previous N days of sessions
      fetchCurrentAndPreviousSessions(selectedValue, selectedTutorId, numPriorDays)
        .then(result => {
          if (result.currentSession) {
            // Log the weekly data for debugging
            logWeeklySessionData(result);
            
            
            // Verify the sessionId matches what we set above
            if (sessionId !== result.currentSession.sessionId) {
              console.warn("Session ID mismatch - using the one from fetch result");
              setAnalyticsSessionID(result.currentSession.sessionId);
            }
            
            // Extract session IDs (can be null for days with no sessions)
            const previousSessionIds = result.previousSessions.map(session => session.sessionId);
            
            // Store in state
            setPreviousSessionIds(previousSessionIds);
            
            // Store the full previous sessions data in a global variable for reference
            window.previousSessionsData = result;
            
            // Pre-fetch data only for sessions that have IDs
            result.previousSessions
              .filter(session => session.sessionId !== null)
              .forEach(session => {
                processTranscriptData(session.sessionId, inputType);
              });
          }
        })
        .catch(error => {
          console.error("Error in session fetch:", error);
        });
    } else {
      console.error("Selected period not found in availablePeriods:", selectedValue);
      setAnalyticsSessionID(null);
    }
  } else {
    setAnalyticsSessionID(null);
    setPreviousSessionIds([]);
    window.previousSessionsData = null;
  }
};



// Optional: Helper function to determine if a session has data
const hasSessionData = (sessionId) => {
  return sessionId !== null && sessionId !== undefined;
};

// Optional: Add this function to format dates in a consistent way
const formatSessionDate = (timestamp) => {
  if (!timestamp) return 'Unknown Date';
  
  const date = new Date(timestamp);
  const options = { 
    weekday: 'long',
    year: 'numeric', 
    month: 'short', 
    day: 'numeric'
  };
  
  return date.toLocaleDateString(undefined, options);
};

// You might also want to add these utility functions to help with debugging
const logWeeklySessionData = (result) => {
  if (!result || !result.currentSession) return;
};


  const handleSessionIdChange = (e) => {
    const newSessionId = e.target.value;
    setTranscriptSessionID(newSessionId);
    
    // Clear the cache when session ID changes
    if (newSessionId !== transcriptSessionID) {
      setSessionDataCache({
        transcript: null,
        analytics: null
      });
    }
  };

  const handleselectedRegionChange = (e) => {
    setSelectedRegion(e.target.value);
    fetchTutors(e.target.value); 
  };

  const handleSelectedMetricChange = (e) => {
    setSelectedMetric(e.target.value);
  };

  const handleCellClick = (params) => {
    const userId = params.row.userId;
    const isSelected = selectedTutorIds.includes(userId);

    if (isSelected) {
      // Remove from selectedTutorIds
      setSelectedTutorIds(prevIds => prevIds.filter(id => id !== userId));
    } else {
      // Add to selectedTutorIds
      setSelectedTutorIds(prevIds => [...prevIds, userId]);
    }
  };


  const handleTutorSelection = (selection) => {
    setSelectedTutorIds(selection.selectionModel);
  }

  // Function that handles the retrieval of the number of sessions by day
  const handleSessionSendClick = async () => {

    if(selectedDate === ""){
      setSessionFlag(true);
      return;
    }

    setSessionLoading(true);
    try {
      await isTokenExpired();
      let authToken = sessionStorage.getItem("access_token");
      // Convert the selected date to epoch format
      const epochDate = new Date(selectedDate).getTime();

      // Make the API request with the epoch date
      const response = await axios.get(
        `https://api.hatproject.org/v1/analysis/sessiondata?summaryByDay&dayTime=${epochDate}`,
        {
          headers: {
            Authorization: authToken,
          },
        }
      );
      setAuthError(false);
      setSessionFlag(false);
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setAuthError(true);
    } finally {
      setSessionLoading(false);
    }
  };

  // Function which handles the download to CSV of the number of sessions by day
  const handleSessionDownloadClick = async() => {
    if(selectedDate === ""){
      setSessionFlag(true);
      return;
    }
    setSessionDownload(true);
    if( data.length === 0 || data[0]._id !== selectedDate) {
      let newSessionData = [];
      try {
        await isTokenExpired();
        let authToken = sessionStorage.getItem("access_token");
        // Convert the selected date to epoch format
        const epochDate = new Date(selectedDate).getTime();
        
        // Make the API request with the epoch date
        const reResponse = await axios.get(
          `https://api.hatproject.org/v1/analysis/sessiondata?summaryByDay&dayTime=${epochDate}`,
          {
            headers: {
              Authorization: authToken,
            },
          }
        );
        setAuthError(false);
        setSessionFlag(false);
        newSessionData = reResponse.data;
        setData(reResponse.data);

        const fileName = `SessionsByDay_${rearrangedDate}_to_${formattedEndDate}`;
        const exportType = exportFromJSON.types.csv;
        exportFromJSON({ data: newSessionData, fileName: fileName, exportType: exportType});
      } catch (error) {
        console.error("Error fetching data:", error);
        setAuthError(true);
      } 
    }
    else {
    const fileName = `SessionsByDay_${rearrangedDate}_to_${formattedEndDate}`;
    const exportType = exportFromJSON.types.csv;
    exportFromJSON({ data: data, fileName: fileName, exportType: exportType});
    }
    setSessionDownload(false);
  };

  // Function that handles the retrieval of the annotated transcript for the user provided session id and school year
  const handleTranscriptSendClick = async () => {
    if (transcriptSessionID === "") {
      setTranscriptFlag(true);
      setNotFoundError("");
      return;
    }
      // Check if we have this data in cache
  if (sessionDataCache.transcript && 
    sessionDataCache.transcript.sessionId === transcriptSessionID && 
    sessionDataCache.transcript.inputType === inputType) {
  // Use cached data
  setTranscriptData(sessionDataCache.transcript.data);
  setTranscriptFlag(false);
  setNotFoundError("");
  return sessionDataCache.transcript.data;
}

    setTranscriptLoading(true);
    try {
      await isTokenExpired();
      let authToken = sessionStorage.getItem("access_token");
      // Make the API request
      const response = await axios.get(
        `https://api.hatproject.org/v1/analysis/sessiondata?transcript=${transcriptSessionID}&input_type=${inputType}`,
        {
          headers: {
            Authorization: authToken,
          },
        }
      );
      setAuthError(false);
      setTranscriptFlag(false);
      setNotFoundError("");
      setTranscriptData(response.data);
      setSessionDataCache(prev => ({
        ...prev,
        transcript: {
          sessionId: transcriptSessionID,
          inputType: inputType,
          data: response.data
        }
      }));
      
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // 404 error - session not found
        setTranscriptData([]);
        const errorMessage = `Session ${transcriptSessionID} not found for school year ${schoolYear}`
        console.warn(errorMessage, error);
        setNotFoundError(errorMessage);
      } else {
        const errorMessage = `The data could not be retrieved. The error code ${error.response.status} was received`;
        console.warn(errorMessage, error);
        setNotFoundError(errorMessage);
        setAuthError(true); 
      }
    } finally {
      setTranscriptLoading(false);
    }
  };
  

  const handleTranscriptDownloadClick = async () => {
    // Check if a session ID is provided
    if (transcriptSessionID === "") {
      setTranscriptFlag(true); 
      setNotFoundError("");
      return;
    }
  
    // Indicate that the transcript download is in progress
    setTranscriptDownload(true);
    setTranscriptFlag(false);
    setNotFoundError(""); 
  
    // Check if transcriptData has valid data for the selected session ID
    if (transcriptData.length > 0 && transcriptData[0].sessionId === transcriptSessionID && transcriptData[0].input_type === inputType) {
      const segments = transcriptData.map((record) => record.segments);
      const flattenedSegments = segments.reduce((acc, val) => acc.concat(val), []);
  
      const segmentsData = flattenedSegments.map(segment => {
        const annotations = segment.annotations || [];
        const talkMoveAnnotation = annotations.find(annotation => annotation.annotationType === "talk_move");
        const talkMoveCategory = talkMoveAnnotation ? talkMoveAnnotation.category : '';
        const talkMoveType = talkMoveAnnotation ? talkMoveAnnotation.values[0] : '';
        const classificationProbability = talkMoveAnnotation 
          ? Array.isArray(talkMoveAnnotation.probability)
            ? Math.max(...talkMoveAnnotation.probability.map(Number)).toFixed(3)
            : talkMoveAnnotation.probability // For single string case
          : '';
  
        return {
          statementId: segment.id,
          speakerId: segment.participantId,
          speakerType: segment.speakerType,
          chatOrSpeech: segment.type,
          start: segment.start,
          end: segment.end,
          text: segment.text,
          talkMoveCategory,
          talkMoveType,
          classificationProbability,
        };
      });
  
      // Download the data as CSV
      const fileName = `AnnotatedTranscript_${transcriptSessionID}`;
      const exportType = exportFromJSON.types.csv;
      exportFromJSON({ data: segmentsData, fileName: fileName, exportType: exportType });
  
    } else {
      // If no data or session ID is different, make an API call to fetch new data
      let newTranscriptData = [];
      try {
        await isTokenExpired();
        const authToken = sessionStorage.getItem("access_token");
  
        // API call to fetch the transcript data
        const reResponse = await axios.get(
          `https://api.hatproject.org/v1/analysis/sessiondata?transcript=${transcriptSessionID}&input_type=${inputType}`,
          {
            headers: {
              Authorization: authToken,
            },
          }
        );
  
        // Store the fetched data
        setAuthError(false);
        setTranscriptFlag(false);
        newTranscriptData = reResponse.data;
        setTranscriptData(newTranscriptData);
  
        // Flatten and process the segments data
        const segments = newTranscriptData.map((record) => record.segments);
        const flattenedSegments = segments.reduce((acc, val) => acc.concat(val), []);
  
        const newSegmentsData = flattenedSegments.map(segment => {
          const annotations = segment.annotations || [];
          const talkMoveAnnotation = annotations.find(annotation => annotation.annotationType === "talk_move");
          const talkMoveCategory = talkMoveAnnotation ? talkMoveAnnotation.category : '';
          const talkMoveType = talkMoveAnnotation ? talkMoveAnnotation.values[0] : '';
          const classificationProbability = talkMoveAnnotation 
          ? Array.isArray(talkMoveAnnotation.probability)
            ? Math.max(...talkMoveAnnotation.probability.map(Number)).toFixed(3)
            : talkMoveAnnotation.probability // For single string case
          : '';
  
          return {
            statementId: segment.id,
            speakerId: segment.participantId,
            speakerType: segment.speakerType,
            chatOrSpeech: segment.type,
            start: segment.start,
            end: segment.end,
            text: segment.text,
            talkMoveCategory,
            talkMoveType,
            classificationProbability,
          };
        });
  
        // Download the new data as CSV
        const fileName = `AnnotatedTranscript_${transcriptSessionID}`;
        const exportType = exportFromJSON.types.csv;
        exportFromJSON({ data: newSegmentsData, fileName: fileName, exportType: exportType });
  
      } catch (error) {
        // Handle errors appropriately
        if (error.response && error.response.status === 404) {
          setTranscriptData([]); // Clear the data
          const errorMessage = `Session ${transcriptSessionID} not found for school year ${schoolYear}`;
          console.warn(errorMessage, error);
          setNotFoundError(errorMessage); // Display 404 error message in the UI
        } else {
          const errorMessage = `The data could not be retrieved. Error code: ${error.response.status}`;
          console.warn(errorMessage, error);
          setNotFoundError(errorMessage); // Display generic error message
          setAuthError(true); // Indicate an authorization error
        }
      }
    }
  
    // Indicate that the download process is complete
    setTranscriptDownload(false);
  };
  

  // Function that handles metric viewing of averages for the selected tutors from the displayed list
  const handleAveragesViewClick = async (selectedTutorIds,startDate,endDate,selectedMetric) => {
    setAveragesData([]);
    setAveragesLoading(true);
    let newAveragesData = [];
    selectedTutorIds.sort();
    for ( const tutorId of selectedTutorIds) {

        await isTokenExpired();
        let authToken = sessionStorage.getItem("access_token");
        const response = await retrieveAveragesData(tutorId, startDate, endDate, selectedMetric, inputType, authToken);
        // check if response.data is falsy or is an empty array
        if (!response.data || response.data.length === 0) {
          const temp = {
            tutorId: tutorId,
            sessions: [],
            label: [],
            averages: [],
          };
          newAveragesData.push(temp);
        }
        // Format the data to the desired structure
        else{
          const temp = response.data.reduce((acc, item) => {
            if (!acc.tutorId) {
              acc.tutorId = item.tutorId;
              acc.sessions = [];
              acc.label = [];
              acc.averages = [];
            }
            acc.label.push(item.weekBeginning);
            acc.averages.push(item.data[0].average);
            acc.sessions.push(item.sessions);
            return acc;
          }, {});
          newAveragesData.push(temp);
        }

    }
    setAveragesData(prevData => [...prevData, ...newAveragesData]);
    setChartMetric(selectedMetric);
    setAveragesLoading(false);
  }

  // Function to retrieve averages data for a specific tutor
  const retrieveAveragesData = async (tutorId, startDate, endDate, selectedMetric, inputType, authToken) => {

    // if the metric we want is studentTalkPercentage, we need will use metric=all and
    // then calculate the percentage in the frontend by using tutorUtteranceCount and studentUtteranceCount
    var tmpMetric = selectedMetric;
    var response = {};
    if (selectedMetric === "studentTalkPercentage") {
      tmpMetric = "all";
    }
    try {
      response = await axios.get(
          `https://api.hatproject.org/v1/analysis/averages?metric=${tmpMetric}&tutorId=${tutorId}&fromDate=${startDate}&toDate=${endDate}&input_type=${inputType}`,
        {
          headers: {
            Authorization: authToken,
          },
        }
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    if (selectedMetric=== "studentTalkPercentage") {
      // loop through the response data and calculate the percentage for each week
      // data format is response.data[].periods[0].metrics[]['metric'] == studentUtteranceCount
      response.data.forEach(item => {
        var studentUtteranceMetricObj = item.periods[0].metrics.find(metric => metric.metric === "studentUtteranceCount")
        const studentUtteranceCount = studentUtteranceMetricObj.data[0].average;
        var tutorUtteranceMetricObj = item.periods[0].metrics.find(metric => metric.metric === "tutorUtteranceCount")
        const tutorUtteranceCount = tutorUtteranceMetricObj.data[0].average;
        const studentTalkPercentage = Math.round((studentUtteranceCount / (studentUtteranceCount + tutorUtteranceCount)) * 100);
        const sessionCount = item.periods[0].sessionCounts;
        item.data = [{ average: studentTalkPercentage }];
        item.sessions = sessionCount;
      });
    }
    else {
      // ensure that values for each week are rounded to the nearest integer
      response.data.forEach(item => {
        item.data[0].average = parseFloat(item.data[0].average).toFixed(0);
      });
    }

    return response;
  }


// Function that handles the LLM prompt summary
const handleLLMSummaryClick = async () => {
  setLLMLoading(true);
  try {
    await isTokenExpired();
    let authToken = sessionStorage.getItem("access_token");
    const data = {
      prompt: promptData,
      sessionId: transcriptSessionID,
      model: selectedModel,
      input_type: inputType,
    }
    const response = await axios.post(
      `https://api.hatproject.org/v1/llmResponse`, data,
      {
        headers: {
          Authorization: authToken,
        },
      }
    );
    const formattedResponse = JSON.stringify(response.data, null, 2);
    setShowLLMSummary(true);
    setLLMSummary(formattedResponse);
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setLLMLoading(false);
  }
};

//handleTalkmoves function
const latestSessionIdRef = useRef(transcriptSessionID);
//useEffect hook runs a side effect whenever transcriptSessionID changes
useEffect(() => {
    latestSessionIdRef.current = transcriptSessionID;
}, [transcriptSessionID]);


const handleTalkmoves = async () => {
  if (transcriptSessionID === "") {
    setAnalyticsFlag(true);
    setAnalyticsNotFoundError("");
    return;
  }
  // Check if we have this data in cache
  if (sessionDataCache.analytics && 
    sessionDataCache.analytics.sessionId === transcriptSessionID && 
    sessionDataCache.analytics.inputType === inputType) {
  // Use cached data
  setAuthError(false);
  setAnalyticsFlag(false);
  setAnalyticsNotFoundError("");
  setAnalyticsSessionID(sessionDataCache.analytics.data[0].sessionId);
  
  const extractCountsInOrder = (data, desiredOrder) => {
    return desiredOrder.map(label => {
      const category = data.find(item => item.label === label);
      return category ? category.count : 0;
    });
  };
  
  const data = sessionDataCache.analytics.data;
  setTutorTalkMoves(extractCountsInOrder(data[0].metrics.tutorTalkmoveCategories, talkMovesLabels));
  setStudentTalkMoves(extractCountsInOrder(data[0].metrics.studentTalkmoveCategories, talkMovesLabels));
  setSessionOverview(data[0].infoBoxes[0].boxContent[10].infoText.join(""));
  setMathTopicsCovered(data[0].infoBoxes[0].boxContent[9].infoText.join(""));
  setFeedbackSessionHighlights(data[0].infoBoxes[0].boxContent[8].infoText.join(""));
  const newStudentParticipation = data[0].participantContributions.filter(
    (entry) => entry.participantType === "student"
  );
  setStudentParticipation(newStudentParticipation);
  setAnalyticsLoading(false);
  return sessionDataCache.analytics.data;
}
  const currentFetchSessionId = transcriptSessionID;
  setAnalyticsLoading(true);
  setStudentParticipation([]); // Clear previous data
  try {
    await isTokenExpired();
    let authToken = sessionStorage.getItem("access_token");
    // Make the API request
    const response = await axios.get(
      `https://api.hatproject.org/v1/analysis/sessiondata?sessionId=${currentFetchSessionId}&input_type=${inputType}`,
      {
        headers: {
          Authorization: authToken,
        },
      }
    );
    if (currentFetchSessionId !== latestSessionIdRef.current) {
      setAnalyticsLoading(false);
      return;
  }
    setAuthError(false);
    setAnalyticsFlag(false);
    setAnalyticsNotFoundError("");
    setAnalyticsSessionID(response.data[0].sessionId);

    const extractCountsInOrder = (data, desiredOrder) => {
      return desiredOrder.map(label => {
        const category = data.find(item => item.label === label);
        return category ? category.count : 0;  // Return 0 if the category is not found
      });
    };
    setTutorTalkMoves(extractCountsInOrder(response.data[0].metrics.tutorTalkmoveCategories, talkMovesLabels));
    setStudentTalkMoves(extractCountsInOrder(response.data[0].metrics.studentTalkmoveCategories, talkMovesLabels));
    setSessionOverview(response.data[0].infoBoxes[0].boxContent[10].infoText.join(""));
    setMathTopicsCovered(response.data[0].infoBoxes[0].boxContent[9].infoText.join(""));
    setFeedbackSessionHighlights(response.data[0].infoBoxes[0].boxContent[8].infoText.join(""));
     const newStudentParticipation = response.data[0].participantContributions.filter(
      (entry) => entry.participantType === "student"
    );
    setStudentParticipation(newStudentParticipation);
    setSessionDataCache(prev => ({
      ...prev,
      analytics: {
        sessionId: transcriptSessionID,
        inputType: inputType,
        data: response.data
      }
    }));
    
    return response.data;
  } 
  catch (error) {
    setStudentParticipation([]);
    //first check for error.response and error.response.status
    if (error.response && error.response.status) {
      if (error.response.status === 404) {
        const errorMessage = `Session ${transcriptSessionID} not found for school year ${schoolYear}`;
        console.warn(errorMessage, error);
        setAnalyticsNotFoundError(errorMessage);
      } else {
        const errorMessage = `The data could not be retrieved. The error code ${error.response.status} was received`;
        console.warn(errorMessage, error);
        setAnalyticsNotFoundError(errorMessage);
        setAuthError(true); 
      }
    } else {
      const errorMessage = `An unexpected error occurred: ${error.message}`;
      //console.warn(errorMessage, error);
      //setAnalyticsNotFoundError(errorMessage);
    }
  } finally {
    setAnalyticsLoading(false);
  }
};
// Add this function to handle the "Retrieve Data" button click
const handleDataRetrievalClick = async () => {
  if (transcriptSessionID === "") {
    setTranscriptFlag(true);
    setAnalyticsFlag(true);
    return;
  }
  setTranscriptLoading(true);
  setAnalyticsLoading(true);
  // Launch both data fetches in parallel
  const transcriptPromise = handleTranscriptSendClick();
  const analyticsPromise = handleTalkmoves();
  
  try {
    await Promise.all([transcriptPromise, analyticsPromise]);
    setActiveVisualization('transcript');
    setShowGraphs(false);
    setShowTranscriptTable(true);
    setShowLLMSummary(false);
    setTranscriptLoading(false);
    setAnalyticsLoading(false);
  } catch (error) {
    
    setTranscriptLoading(false);
    setAnalyticsLoading(false);
  }
};



  // Formatting the data for the sessions per day graph
  const NoSessions = data.map((record) => record.count);
  const sessionID = data.map((record) => record._id);

  // Line graph data
  const data_session = {
    labels: sessionID,
    datasets: [
      {
        label: "Number of sessions",
        data: NoSessions,
        backgroundColor: "rgba(180,130,90,0.2)",
        borderColor: "rgba(75,0,0,1)",
      },
    ],
  };

  const options_session = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: "Number of sessions",
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: (value) => value,
        color: 'black',
        font: {
          weight: 'bold',
        },
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: "Number of Sessions",
        },
      },
      x: {},
    },
    
  };

  // Function to retrieve the list of regions available
  const fetchRegion = async () => {
    try {
      await isTokenExpired();
      let authToken = sessionStorage.getItem("access_token");
      const response = await axios.get('https://api.hatproject.org/v1/analysis/sessiondata?userRegions',
      {
        headers: {
          Authorization: authToken,
        },
      }
      ); 
      var newRegionList = response.data.sort();
      setRegionList(newRegionList)
    } catch (error) {
      console.error('Error fetching session options:', error);
    }
  };

  // Function to retrieve all active tutors from the selected region.
  // In addition to the standard tutor user data, we also retrieve
  // the most recent session and total sesssion count for each tutor
  // in the region and add the 'sessionCount' and 'readableDate' fields
  // to the tutor objects. These values are used to assist the user
  // when choosing a tutor

  const fetchTutors = async (region) => {
    try {
      await isTokenExpired();
      let authToken = sessionStorage.getItem("access_token");
      const response = await axios.get(`https://api.hatproject.org/v1/analysis/sessiondata?userRole=tutor&region=${region}`, 
      {
        headers: {
          Authorization: authToken,
        },
      }
      );

      var newTutorList = response.data;

      await isTokenExpired();
      const sessionTimeResponse = await axios.get(
        `https://api.hatproject.org/v1/analysis/sessiondata?mostRecentSessionTime&input_type=${inputType}&region=${region}`,
        {
          headers: {
            Authorization: authToken,
          },
        }
      );
      const sessionTimeData = sessionTimeResponse.data;
      const sessionTimeMap = new Map();
      sessionTimeData.forEach(item => {
        sessionTimeMap.set(item.participantId, {
          sessionCount: item.sessionCount,
          readableDate: item.readableDate
        });
      });
      // Add sessionCount and readableDate to each tutor
      newTutorList = newTutorList.map(tutor => {
        const sessionInfo = sessionTimeMap.get(tutor.userId) || {};
        return {
          ...tutor,
          sessionCount: sessionInfo.sessionCount || 0,
          readableDate: sessionInfo.readableDate || ''
        };
      });

      newTutorList.sort((a, b) => a.name.localeCompare(b.name));   // sort by name

      setTutorList(newTutorList);
      // Discards other tutors if the region is changed
      setSelectedTutorIds([]);
    } catch (error) {
      console.error('Error fetching session options:', error);
    }
  };

  // Runs on page load to fetch the region list
  useEffect(() => {
    fetchRegion();
  }, []);

  //Runs when inputType changes to map school year accordingly
  useEffect(() => {
    const correspondingSchoolYear = inputTypeToSchoolYearMap[inputType];
    if (correspondingSchoolYear) {
      setSchoolYear(correspondingSchoolYear);
    }
  }, [inputType]);

  // Function to handle the logout
  const handleLogout = () => {
    sessionStorage.clear();
    navigate('/');
  };

  // Function to check if token is expired and refresh auth token if necessary
  const isTokenExpired = async() => {
    let expiryTime = sessionStorage.getItem('token_expiry');
    expiryTime = parseInt(expiryTime, 10);
    if (Date.now() < expiryTime - 60000)
    {
      return true;
    }
    else {
      try {
        const response = await axios.post(
          'https://hat-server.auth.us-east-1.amazoncognito.com/oauth2/token',
          new URLSearchParams({
            grant_type: 'refresh_token',
            client_id: userpool.clientId,
            refresh_token: sessionStorage.getItem('refresh_token'),
          }),
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            }
          }
        );
        sessionStorage.setItem("access_token", response.data.id_token);
        let newExpiryTime = expiryTime + 3600 * 1000;
        sessionStorage.setItem("token_expiry", newExpiryTime);
        return true;
      } catch (error) {
        console.error('Error refreshing token:', error);
      }

    }
  };

  const TextCard = ({ title, text }) => {
    return (
      <Card sx={{ width: 350, height: 500, margin: 2, boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h6" align="center" gutterBottom>
            {title}
          </Typography>
          <Typography variant="body1" align="left" sx={{ marginTop: 4 }}>
            <div
              dangerouslySetInnerHTML={{ __html: text }} // Render sanitized HTML
            />
          </Typography>
        </CardContent>
      </Card>
    );
  };


// Add this function to handle tutor selection
const handleTutorChange = (e) => {
  setSelectedTutorId(e.target.value);
};

  const retrieveSagaUserRegion = async (userId) => {
    try {
      const authToken = sessionStorage.getItem("access_token");
      await isTokenExpired();
      
      const response = await axios.get(
        `https://api.hatproject.org/v1/analysis/sessiondata?userRole=tutor`,
        {
          headers: { Authorization: authToken }
        }
      );
  
      if (response.status !== 200) {
        return null;
      }
      const userRecord = response.data.find(user => user.userId === userId);
      if (!userRecord) {
        return null;
      }

      return userRecord.region;
    } catch (error) {
      return null;
    }
  };

  
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const apiCache = new Map();
  const processTranscriptData = useCallback(async (transcriptSessionID, inputType) => {
    if (!transcriptSessionID) {
      setError("Session ID is required");
      return null;
    } 
    const cacheKey = `${transcriptSessionID}-${inputType}`;
    if (apiCache.has(cacheKey)) {
      return apiCache.get(cacheKey);
    }
  
    setIsLoading(true);
    setError(null);
  
    try {
      const authToken = sessionStorage.getItem("access_token");
      await isTokenExpired();
      
      const response = await axios.get(
        `https://api.hatproject.org/v1/analysis/sessiondata?sessionId=${transcriptSessionID}&input_type=${inputType}`,
        {
          headers: { Authorization: authToken }
        }
      );
      // Extract session data from the first item in the array
      const sessionData = response.data[0];
      const { sessionDiscourseHighlights, metrics, participantContributions, sessionMetadata} = sessionData;
      
      // Transform keeping all fields from sessionDiscourseHighlights
      const processedData = sessionDiscourseHighlights.map(highlight => ({
        // Keep all original fields
        category: highlight.category,
        end: highlight.end,
        noticingScore: highlight.noticingScore,
        participantId: highlight.participantId,
        segmentId: highlight.segmentId,
        speakerType: highlight.speakerType,
        start: highlight.start,
        text: highlight.text,
        type: highlight.type,
        
        // Add any additional derived fields needed for the tree
        statementId: highlight.segmentId,
        talkMoveCategory: highlight.category || '',
        talkMoveType: highlight.type || ''
      }));

      // determine activeParticipants as a subset of sessionMetadata.participants by checking 
      // if the participants.id = participantContributions.participantId
      const activeParticipants = sessionMetadata.participants
      .filter(participant =>
        participant.type === 'student' && // Ensure the participant type is "student"
        participantContributions.some(contributor => contributor.participantId === participant.id)
      )
      .map(participant => ({
        typeof: participant.type,
        id: participant.id,
        name: participant.name
      })); 
      // calculate an accurate endTime based on startTime + metrics.sessionTime
      const realEndTime = sessionMetadata.startTime + (metrics.sessionTime * 1000);
      const sessionMeta = {
        activeParticipants: activeParticipants,
        participants: sessionMetadata.participants,
        siteDirectors: sessionMetadata.siteDirectors,
        sessionId: sessionMetadata.sessionId,
        content: sessionMetadata.content,
        title: sessionMetadata.title,
        tutorId: sessionMetadata.tutorId,
        startTime: sessionMetadata.startTime,
        endTime: realEndTime
      }  
      // Add metrics to the cache as well
      const enrichedData = {
        sessionMetadata:sessionMeta,
        processedData,
        metrics: {
          tutorMetrics: {
            talkMoves: metrics.tutorTalkMoves,
            talkMovesCategories: metrics.tutorTalkmoveCategories,
            utteranceCount: metrics.tutorUtteranceCount,
            talkTime: metrics.tutorTalkTime,
            mathTermCount: metrics.tutorMathTermCount
          },
          studentMetrics: {
            talkMoves: metrics.studentTalkMoves,
            talkMovesCategories: metrics.studentTalkmoveCategories,
            utteranceCount: metrics.studentUtteranceCount,
            talkTime: metrics.studentTalkTime,
            mathTermCount: metrics.studentMathTermCount
          },
          sessionMetrics: {
            sessionTime: metrics.sessionTime,
            totalUtterances: metrics.totalUtterances,
            totalMathTermCount: metrics.totalMathTermCount,
            longWaitTimes: metrics.longWaitTimes,
            shortWaitTimes: metrics.shortWaitTimes
          }
        }
      };
      // Store the enriched data in cache
      apiCache.set(cacheKey,  enrichedData);
      setIsLoading(false);
      return enrichedData;
    } catch (error) {
      let errorMessage;
      if (error.response?.status === 404) {
        errorMessage = `Session ${transcriptSessionID} not found`;
      } else {
        errorMessage = `The data could not be retrieved. The error code ${error.response?.status} was received`;
      }
      setError(errorMessage);
      setIsLoading(false);
      return null;
    }
  }, []);

  const [selectedTutor, setSelectedTutor] = useState('');
  const fetchSessionMetadata = async (startTime, endTime) => {
    try {
      await isTokenExpired();
      let authToken = sessionStorage.getItem("access_token");
      
      // Use the provided API endpoint
      const url = `https://api.hatproject.org/v1/analysis/sessiondata?field=sessionMetadata&startTime=${startTime}&endTime=${endTime}`;
      
      const response = await axios.get(url, {
        headers: {
          Authorization: authToken,
        },
        timeout: 10000 // 10 second timeout
      });
      if (response.data && Array.isArray(response.data)) {
        // Convert array of metadata objects to a map keyed by sessionId
        const metadataMap = {};
        response.data.forEach(item => {
          if (item.sessionMetadata && item.sessionMetadata.sessionId) {
            metadataMap[item.sessionMetadata.sessionId] = {
              title: item.sessionMetadata.title || 
                    (item.sessionMetadata.content ? item.sessionMetadata.content.title : 'No Title'),
              participants: item.sessionMetadata.participants || [],
              startTime: item.sessionMetadata.startTime,
              endTime: item.sessionMetadata.endTime
            };
          }
        });
       
        return metadataMap;
      } else {
        return {};
      }
    } catch (error) {
      return {};
    }
  };
  const fetchAvailablePeriods = async () => {
    setAvailablePeriods([]);
    try {
      await isTokenExpired();
      let authToken = sessionStorage.getItem("access_token");
      
      // Get the timezone for the selected region
      const regionTimezone = getTimeZoneForRegion(selectedRegion);
      
      // Use the utility function from your timezone.js to get proper timestamp range
      const { startTime: startTimeParam, endTime: endTimeParam } = 
        getTimestampRangeForDate(selectedDate, selectedRegion);
      
      // 3. Fetch session metadata first to have titles available
      const sessionMetadata = await fetchSessionMetadata(startTimeParam, endTimeParam);
      
      // 4. Fetch sessions for the tutor on the selected date
      const url = `https://api.hatproject.org/v1/analysis/sessiondata?getByTutorHour&endTime=${endTimeParam}&startTime=${startTimeParam}&tutorId=${selectedTutorId}`;
      
      // 5. Add response timeout and better error handling
      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: authToken,
          },
          timeout: 10000 // 10 second timeout
        });
  
        // Process response only if it's valid
        if (response.data && Array.isArray(response.data) && response.data.length > 0) {
          // Collect all sessions from all hours
          let allSessions = [];
          
          // Loop through each hour entry
          response.data.forEach(hourEntry => {
            if (hourEntry && Array.isArray(hourEntry.sessions)) {
              // Add these sessions to our collection
              allSessions = [...allSessions, ...hourEntry.sessions];
            }
          });  
          
          if (allSessions.length > 0) {
            // Create period options based on all sessions with metadata
            const periodsPromises = allSessions.map(async (session) => {
              // Ensure startTime is valid
              const startTime = new Date(session.startTime);
              if (isNaN(startTime.getTime())) {
                console.error("Invalid session start time:", session.startTime);
                return null;
              }
              
              // Get session metadata if available
              const metadata = sessionMetadata[session.sessionId] || {};
              const sessionTitle = metadata.title || 'No Title Available';
              
              // Fetch active participants for this session
              let studentNames = [];
              try {
                const enrichedData = await processTranscriptData(
                  session.sessionId, 
                  inputType
                );
                
                // Extract student names from the sessionMetadata, sorted alphabetically
                if (enrichedData && enrichedData.sessionMetadata) {
                  studentNames = enrichedData.sessionMetadata.activeParticipants
                    //.map(p => p.name)
                    .map(p => p.id)
                    .sort((a, b) => a.localeCompare(b)); // Sort alphabetically
                }
              } catch (error) {
                console.error(`Error fetching students for session ${session.sessionId}:`, error);
              }
              
              // Use the formatTimestampInRegionTimezone utility function
              const formattedTime = formatTimestampInRegionTimezone(
                startTime.getTime(), 
                selectedRegion
              );
              
              return {
                id: `${session.sessionId}_${startTime.getTime()}`,
                label: formattedTime,
                sessionId: session.sessionId,
                startTime: startTime.getTime(),
                title: sessionTitle,
                students: studentNames
              };
            });
            
            // Wait for all periods to be processed
            const periods = await Promise.all(periodsPromises);
            
            // Remove any null entries and sort
            const sortedPeriods = periods
              .filter(Boolean)
              .sort((a, b) => a.startTime - b.startTime);
            
            setAvailablePeriods(sortedPeriods);
          } 
        }
      } catch (apiError) {
        console.error("API Error:", apiError);
      }
    } catch (error) {
      console.error("General error in fetchAvailablePeriods:", error);
    }
  };
  

  useEffect(() => {
    // Ensure both selectedTutor and selectedDate are valid
    if (!selectedTutor || !selectedDate) {
      return; // Exit early if values are invalid
    }
    fetchAvailablePeriods();
  }, [selectedTutor, selectedDate]);
  
  
  // const TileWithPieChart = ({ title, values}) => {
  //   const data_pie = {
  //     labels: talkMovesLabels,
  //     datasets: [
  //       {
  //         data: values,
  //         backgroundColor: ["#40E0D0", "#FFA500", "#FF6347"],
  //         hoverBackgroundColor: ["#36D7B7", "#FFB347", "#FF7F7F"]
  //       }
  //     ]
  //   }
  //   const options_pie = {
  //     responsive: true,
  //     plugins: {
  //       legend: {
  //         position: 'bottom',
  //       },
  //       datalabels: {
  //         color: '#fff',              // Label color
  //         font: {
  //           weight: 'bold',
  //           size: 12
  //         },
  //         formatter: (value, context) => {
  //           const label = context.chart.data.labels[context.dataIndex];  // Static label
  //           const total = values.reduce((sum, val) => sum + val, 0); // Calculate the total
  //           return `${label}\n${value} / ${total} `; // Display in format "value / total (percentage%)"
  //         },
  //         align: 'center',             // Position of label inside the segment
  //         anchor: 'center',              // Position of label outside the segment
  //         clamp: true,
  //         // clip: true,
  //       }
  //     }
  //   };

  //   return (
  //     <Card sx={{ width: 400, height: 500, margin: 2, boxShadow: 3 }}>
  //       <CardContent>
  //         <Typography variant="h6" align="center" gutterBottom>
  //           {title}
  //         </Typography>
  //         <Pie data={data_pie} options={options_pie} />
  //       </CardContent>
  //     </Card>
  //   );
  // };

  const TileWithPieChart = ({ title, values }) => {
    const total = values.reduce((sum, val) => sum + val, 0); // Calculate the total value
    const maxDiameter = 500; // Maximum diameter in pixels
    const minDiameter = 300; // Minimum diameter to ensure visibility
    const diameter = Math.min(maxDiameter, Math.max(minDiameter, (total / 50) * maxDiameter)); // Scale diameter based on total
  
    const data_pie = {
      labels: talkMovesLabels,
      datasets: [
        {
          data: values,
          backgroundColor: ["#40E0D0", "#FFA500", "#FF6347"],
          hoverBackgroundColor: ["#36D7B7", "#FFB347", "#FF7F7F"],
        },
      ],
    };
  
    const options_pie = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "bottom",
        },
        datalabels: {
          color: "#000", // Label color
          font: {
            weight: "bold",
            size: 13
          },
          formatter: (value, context) => {
            const label = context.chart.data.labels[context.dataIndex]; // Static label
            return `${label}\n${value} / ${total}`; // Display in format "value / total"
          },
          align: "center", // Position of label inside the segment
          anchor: "center", // Position of label outside the segment
          clamp: true,
        },
      },
    };
  
    return (
      <Card sx={{ width: diameter, height: diameter + 100, margin: 2, boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h6" align="center" gutterBottom>
            {title}
          </Typography>
          <div style={{ width: diameter, height: diameter, margin: "0 auto" }}>
            <Pie data={data_pie} options={options_pie} />
          </div>
        </CardContent>
      </Card>
    );
  };


  const StudentTalkParticipation = ({ title }) => {
    const data_pie = {
      labels: studentParticipation.map((entry) => entry.participantId),
      datasets: [
        {
          data: studentParticipation.map((entry) => entry[participationMetric]),
          backgroundColor: ["#40E0D0", "#FFA500", "#FF6347"],
          hoverBackgroundColor: ["#36D7B7", "#FFB347", "#FF7F7F"]
        }
      ]
    }
    const options_pie = {
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom',
        },
        datalabels: {
          color: '#000',
          font: {
            weight: 'bold',
            size: 13
          },
          formatter: (value, context) => {
            const label = context.chart.data.labels[context.dataIndex]; 
            const total = studentParticipation.map((entry) => entry[participationMetric]).reduce((sum, val) => sum + val, 0).toFixed(0);              
            return `${label}\n${value} / ${total} `; 
          },
          align: 'center',            
          anchor: 'center',            
          clamp: true,
          // clip: true,
        }
      }
    };

    return (
      <Card sx={{ width: 400, height: 600, margin: 2, boxShadow: 3, display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
        <FormControl sx={{ minWidth: 150 }}>
          <InputLabel id="metric-select-label">Metric</InputLabel>
          <Select
            labelId="metric-select-label"
            id="metric-select"
            value={participationMetric}
            onChange={(e) => setParticipationMetric(e.target.value)}
            label="Student Activity Metric"
          >
            <MenuItem value="sentences">Sentences</MenuItem>
            <MenuItem value="talkMoves">Talk Moves</MenuItem>
            <MenuItem value="time">Talk Time in seconds</MenuItem>
            <MenuItem value="questions">Questions</MenuItem>              
          </Select>
        </FormControl>
      </Box>


      <CardContent sx={{ flexGrow: 1, textAlign: 'center' }}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Pie data={data_pie} options={options_pie} />
      </CardContent>
    </Card>
    );
  };
  const handleButtonClick = async (visualizationType) => {
    setActiveVisualization(visualizationType);
    
    switch (visualizationType) {
      case 'transcript':
        // Just set UI states appropriately based on cached data
        setShowGraphs(false);
        setShowTranscriptTable(true);
        setShowLLMSummary(false);
        
        // If no data in cache, fetch it
        if (!sessionDataCache.transcript || 
          sessionDataCache.transcript.sessionId !== transcriptSessionID ||
          sessionDataCache.transcript.inputType !== inputType) {
        await handleTranscriptSendClick();
      } else {
        // If data exists in cache, make sure loading state is reset
        setTranscriptLoading(false);
      }
      break;
        
      case 'talkTree':
      case 'participation':
      case 'llm':
        setShowGraphs(true);
        setShowTranscriptTable(false);
        setShowLLMSummary(false);
        
        // If no data in cache, fetch it
        if (!sessionDataCache.analytics || 
            sessionDataCache.analytics.sessionId !== transcriptSessionID ||
            sessionDataCache.analytics.inputType !== inputType) {
          await handleTalkmoves();
        } else {
          // When using cached data, make sure we're not showing loading indicators
          setAnalyticsLoading(false);
        }
        break;
      default:
        break;
    }
  };

return (
  <div style={{ 
    display: "flex", 
    flexDirection: "column", 
    minHeight: "100vh",
  }}>
    {/* Enhanced Horizontal Navbar with better styling */}
    <HorizontalNavbar 
    activeMenu={activeMenu}
    setActiveMenu={setActiveMenu} 
    setActiveVisualization={setActiveVisualization} handleLogout={handleLogout} />

    {/* Main Content Area with enhanced styling */}
    <div style={{ 
      flex: 1, 
      padding: "30px", 
      backgroundColor: "#f8fafc", 
      overflowX: "hidden",
      backgroundImage: "linear-gradient(to bottom, #f8fafc, #f1f5f9)"
    }}>
      
      {/* School Year and Session ID Selection with enhanced styling */}
      <AccordionComponent
        inputType={inputType}
        setInputType={setInputType}
        transcriptSessionID={transcriptSessionID}
        setTranscriptSessionID={setTranscriptSessionID}
        handleSessionIdChange={handleSessionIdChange}
        handleDataRetrievalClick={handleDataRetrievalClick}
        selectedRegion={selectedRegion}
        setSelectedRegion={setSelectedRegion} 
        handleselectedRegionChange={handleselectedRegionChange}
        regionList={regionList}
        fetchTutors ={fetch}
        tutorList = {tutorList}
        setTutorList = {setTutorList}
       setSelectedTutorId={setSelectedTutorId}
        handleTutorChange={handleTutorChange}
        handleDateChange={handleDateChange}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        selectedTutorId={selectedTutorId}
        availablePeriods={availablePeriods}
        selectedPeriod={selectedPeriod}
        setAvailablePeriods={setAvailablePeriods}
        isTokenExpired={isTokenExpired}
        fetchAvailablePeriods={fetchAvailablePeriods}
        handlePeriodChange={handlePeriodChange}
        setSelectedPeriod={setSelectedPeriod}
        setActiveVisualization={setActiveVisualization}
        setShowTranscriptTable={setShowTranscriptTable}
        setShowGraphs={setShowGraphs}
        setShowLLMSummary={setShowLLMSummary}
      />


      {/* Session-level Visualizations Content */}
      {activeMenu === 'Session-level Visualizations' &&
        <div style={{
          background: "white",
          borderRadius: "8px",
          boxShadow: "0 2px 10px rgba(0,0,0,0.05)",
          padding: "25px"
        }}>
              <Grid container spacing={2} alignItems="center" style={{marginBottom: "15px", justifyContent:"center" }}>
                  <Grid item>
                    <Typography variant="h6" align="center">
                      Select any visualization you want to view
                    </Typography>
                  </Grid>
                </Grid>

          <Grid container spacing={2} style={{ marginBottom: '20px',justifyContent:"center" }}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleButtonClick('transcript')}
                style={{
                  textTransform: "none",
                  borderRadius: "6px",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  padding: "8px 16px",
                  backgroundColor: activeVisualization === 'transcript' ? "" : "#f5f7fa",
                  color: activeVisualization === 'transcript' ? "white" : "#5a6776",
                }}
                
              >
                Annotated Transcript
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleButtonClick('talkTree')}
                style={{
                  textTransform: "none",
                  borderRadius: "6px",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  padding: "8px 16px",
                  backgroundColor: activeVisualization === 'talkTree' ? "#1976d2" : "#f5f7fa",
                  color: activeVisualization === 'talkTree' ? "white" : "#5a6776",
                }}
              >
                Talk Tree Visualization
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleButtonClick('participation')}
                style={{
                  textTransform: "none",
                  borderRadius: "6px",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  padding: "8px 16px",
                  backgroundColor: activeVisualization === 'participation' ? "#1976d2" : "#f5f7fa",
                  color: activeVisualization === 'participation' ? "white" : "#5a6776",
                }}
              >
                 Participation Graphs
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleButtonClick('llm')}
                style={{
                  textTransform: "none",
                  borderRadius: "6px",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  padding: "8px 16px",
                  backgroundColor: activeVisualization === 'llm' ? "#1976d2" : "#f5f7fa",
                  color: activeVisualization === 'llm' ? "white" : "#5a6776",
                }}
              >
                LLM Feedback
              </Button>
            </Grid>
          </Grid>

          {/* Transcript Visualization */}
          {activeVisualization === 'transcript' && (
            <>
              {transcriptLoading ? (
                <Typography variant="subtitle1" align="center" gutterBottom>
                  <CircularProgress size={34} style={{ color: "#000" }}/>
                </Typography>
              ) : (
                <>
                  {transcriptFlag && (
                    <Grid container spacing={2} alignItems="center" style={{ padding: 10 }}>
                      <p style={{ color: 'red' }}>Please enter a session ID first.</p>
                    </Grid>
                  )}
                  {notFoundError && (
                    <Grid container spacing={2} alignItems="center" style={{ padding: 10 }}>
                      <p style={{ color: 'red' }}>{notFoundError}</p>
                    </Grid>
                  )}
                  {showTranscriptTable && transcriptData.length > 0 && (
                    <>
                      <Typography variant="subtitle1" align="center" gutterBottom>
                        Session ID: {transcriptData[0].sessionId}
                      </Typography>
                      <Grid container spacing={2} justifyContent="center">
                        <Grid item>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleTranscriptDownloadClick}
                            disabled={transcriptDownload}
                            
                          >
                            {transcriptDownload ? <CircularProgress size={24} /> : "Download"}
                          </Button>
                        </Grid>
                      </Grid>
                      <SegmentsTable segments={transcriptData.map((record) => record.segments)} />
                    </>
                  )}
                </>
              )}
            </>
          )}

          {/* Talk Tree Visualization */}
          {activeVisualization === 'talkTree' && (
            <>
              {analyticsLoading ? (
                <Typography variant="subtitle1" align="center" gutterBottom>
                 <CircularProgress size={34} style={{ color: "#000" }}/>
                </Typography>
              ) : (
                <div>
                  {analyticsFlag && (
                    <Grid container spacing={2} alignItems="center" style={{ padding: 10 }}>
                      <p style={{ color: 'red' }}>Please enter a session ID first.</p>
                    </Grid>
                  )}
                  {analyticsNotFoundError && (
                    <Grid container spacing={2} alignItems="center" style={{ padding: 10 }}>
                      <p style={{ color: 'red' }}>{analyticsNotFoundError}</p>
                    </Grid>
                  )}
                  {showGraphs && tutorTalkMoves.length > 0 && (
                    <div style={{ padding: 20 }}>
                      <Typography variant="h5" align="center" gutterBottom>
                        Talk Moves Distribution
                      </Typography>
                      <Typography variant="subtitle1" align="center" gutterBottom>
                        Session ID: {analyticstSessionID}
                      </Typography>
                      {analyticstSessionID && (
                        <SessionTrees
                        processTranscriptData={processTranscriptData}
                        analyticsSessionID={analyticstSessionID}
                        previousSessionIds={previousSessionIds}
                        retrieveSagaUserRegion={retrieveSagaUserRegion}
                        inputType={inputType}
                        region={selectedRegion}
                      /> 
                      )} 
                    
                    </div>
                  )}
                </div>
              )}
            </>
          )}
          
          {/* Participation Visualization */}
          {activeVisualization === 'participation' && (
            <>
              {analyticsLoading ? (
                <Typography variant="subtitle1" align="center" gutterBottom>
                  <CircularProgress size={34} style={{ color: "#000" }}/>
                </Typography>
              ) : (
                <div>
                  {analyticsFlag && (
                    <Grid container spacing={2} alignItems="center" style={{ padding: 10 }}>
                      <p style={{ color: 'red' }}>Please enter a session ID first.</p>
                    </Grid>
                  )}
                  {analyticsNotFoundError && (
                    <Grid container spacing={2} alignItems="center" style={{ padding: 10 }}>
                      <p style={{ color: 'red' }}>{analyticsNotFoundError}</p>
                    </Grid>
                  )}
                  {showGraphs && tutorTalkMoves.length > 0 && (
                    <div style={{ padding: 20 }}>
                      <Typography variant="h5" align="center" gutterBottom>
                        Talk Moves Distribution
                      </Typography>
                      <Typography variant="subtitle1" align="center" gutterBottom>
                        Session ID: {analyticstSessionID}
                      </Typography>
                      <Grid container justifyContent="center" spacing={2}>
                        <TileWithPieChart title="Tutor Talk Move Distribution" values={tutorTalkMoves} />
                        <TileWithPieChart title="Student Talk Move Distribution" values={studentTalkMoves} />
                        <StudentTalkParticipation title="Activity per Student" />
                      </Grid>
                    </div>
                  )}
                </div>
              )}
            </>
          )}

          {/* LLM Feedback Visualization */}
          {activeVisualization === 'llm' && (
            <>
              {analyticsLoading ? (
                <Typography variant="subtitle1" align="center" gutterBottom>
                  <CircularProgress size={34} style={{ color: "#000" }}/>
                </Typography>
              ) : (
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <div style={{ maxWidth: '900px', width: '100%' }}>
                    {analyticsFlag && (
                      <Grid container spacing={2} alignItems="center" style={{ padding: 10 }}>
                        <p style={{ color: 'red' }}>Please enter a session ID first.</p>
                      </Grid>
                    )}
                    
                    {analyticsNotFoundError && (
                      <Grid container spacing={2} alignItems="center" style={{ padding: 10 }}>
                        <p style={{ color: 'red' }}>{analyticsNotFoundError}</p>
                      </Grid>
                    )}
                    
                    {showGraphs && tutorTalkMoves.length > 0 && (
                      <div style={{ padding: 20 }}>
                        <Typography variant="h5" align="center" gutterBottom>
                          Talk Moves Distribution
                        </Typography>
                        <Typography variant="subtitle1" align="center" gutterBottom>
                          Session ID: {analyticstSessionID}
                        </Typography>
                        
                        {/* TextCards in a centered row with equal width */}
                        <Grid container spacing={4} justifyContent="center">
                          <Grid item xs={12} md={6} style={{ display: 'flex' }}>
                            <div style={{ width: '100%' }}>
                              <TextCard title="Math Topic Covered" text={mathTopicsCovered} />
                            </div>
                          </Grid>
                          <Grid item xs={12} md={6} style={{ display: 'flex' }}>
                            <div style={{ width: '100%' }}>
                              <TextCard title="Session Overview" text={sessionOverview} />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    )}
                    
                    <Grid container spacing={2} direction="column">
                      <Grid item container spacing={1} alignItems="center">
                        <Grid item xs={11}>
                          <TextField
                            style={{ width: '100%' }}
                            id="prompt"
                            label="Enter the prompt"
                            type="text"
                            multiline
                            rows={15}
                            value={promptData}
                            onChange={(e) => setPromptData(e.target.value)}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <MaterialTooltip title={
                            <React.Fragment>
                              <span style={{ fontSize: '1.5em', lineHeight: '1.3' }}>
                                <p>Use this feature to send a request to a ChatGPT model. You may use the supplied prompt or replace it with your own.</p>
                                <p>If you include the pattern: 
                                &nbsp;<strong>&lt;&lt;transcript&gt;&gt;</strong>
                                <br />
                                then the session transcript with speaker attributions (but not timestamps) will be added to the prompt.</p>
                                <p>The transcript will have the form:
                                <blockquote>
                                  <em>tutor [tutorName]: Is the coefficient one?<br />
                                student [studentName]: It's one.</em>
                                </blockquote>
                                with the tutor and student names used instead of "tutorName" and "studentName".
                                </p>
                                <p>
                                Other patterns that you can use are:
                                <ul>
                                <li><strong>&lt;&lt;studentList&gt;&gt;</strong><br/> replaced with a comma-separated list of student names.</li>
                                <li><strong>&lt;&lt;studentCount&gt;&gt;</strong><br/> replaced with the count of "participantContributions.participantType" == "student" objects.</li>
                                <li><strong>&lt;&lt;sessionTitle&gt;&gt;</strong><br/> replaced with either "sessionMetadata.content.title" if it exists and "sessionMetadata.title" if "content" doesn't exist.</li>
                                </ul>
                                Note: Information sent in prompts using a ChatGPT API call are not kept or used for training additional models.
                                </p>
                              </span>
                            </React.Fragment>
                          }>
                            <IconButton>
                              <InfoIcon />
                            </IconButton>
                          </MaterialTooltip>
                        </Grid>
                      </Grid>

                      <Grid item container spacing={2} alignItems="center">
                        <Grid item>
                          <FormControl style={{ width: '200px' }}>
                            <InputLabel id="region-label">Select Model</InputLabel>
                            <Select
                              labelId="model-label"
                              id="selectedmodel"
                              value={selectedModel}
                              onChange={(e) => setSelectedModel(e.target.value)}
                              label="Select Model"
                            >
                              <MenuItem value={'gpt-3.5-turbo'}>gpt-3.5-turbo</MenuItem>
                              <MenuItem value={'gpt-4o'}>gpt-4o</MenuItem>  
                              <MenuItem value={'gpt-4o-mini'}>gpt-4o-mini</MenuItem>  
                              <MenuItem value={'Titan Express V1'}>Titan Express V1</MenuItem>        
                              <MenuItem value={'Titan Text G1 Premier V1'}>Titan Text G1 Premier V1</MenuItem>
                              <MenuItem value={'Llama3 8B Instruct V1'}>Llama3 8B Instruct V1</MenuItem>    
                              <MenuItem value={'Llama 3 70B Instruct'}>Llama 3 70B Instruct</MenuItem>           
                              <MenuItem value={'Mistral 7B Instruct V2'}>Mistral 7B Instruct V2</MenuItem>              
                              <MenuItem value={'Mixtral 8X7B Instruct'}>Mixtral 8X7B Instruct</MenuItem>             
                            </Select>
                          </FormControl>
                        </Grid>
                        
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleLLMSummaryClick()}
                            style={{
                              textTransform: "none",
                              borderRadius: "6px",
                              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                              padding: "8px 16px",
                            }}
                            disabled={llmLoading}
                          >
                            {llmLoading ? <CircularProgress size={24} /> : "View"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    
                    {showLLMSummary && llmSummary && (
                      <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '50px' }}>
                        <Grid item xs={12}>
                          <TextField
                            style={{ width: '100%' }}
                            id="summary"
                            label="Summary"
                            type="text"
                            value={llmSummary}
                            multiline
                            rows={15}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      } 
      {/* Tutor Averages Content */}
      {activeMenu === 'Tutor Averages' && 
        <div className="page-wrapper">
          <div className="content-container">
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <h3>Tutor Averages Viewer</h3>
              </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <FormControl style={{width: '200px'}}>
                  <InputLabel id="region-label">Select Region</InputLabel>
                  <Select
                    labelId="region-label"
                    id="selectedRegion"
                    value={selectedRegion}
                    onChange={handleselectedRegionChange}
                    label="Select Region"
                  >
                    {regionList.map((option,index) => (
                      <MenuItem key={index} value={option} onClick={() => fetchTutors(option)}>
                        {option} 
                      </MenuItem>
                    ))}            
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            
            {selectedRegion && (
              <Grid container spacing={2} direction="column" alignItems="center">
                <Grid item>
                  <h4>Select one or more tutors:</h4>
                </Grid>
                <Grid item xs={12} style={{width: '100%'}}>
                  <Box sx={{ height: 750, width: '100%', paddingBottom: '20px'}}>
                    <DataGrid
                      rows={tutorList}
                      columns={TutorColumns}
                      getRowId={(row) => row.userId}
                      pageSize={10}
                      rowsPerPageOptions={[10]}
                      pagination
                      checkboxSelection
                      onSelectionModelChange={handleTutorSelection}
                      selectionModel={selectedTutorIds}
                      onCellClick={handleCellClick}
                    />
                  </Box>
                </Grid>
                
                <Grid item>
                  <h4>Select a date range:</h4>
                </Grid>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item>
                    <TextField
                      id="start-date"
                      label="Start Date"
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      id="end-date"
                      label="End Date"
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid item>
                  <h4>Select a metric:</h4>
                </Grid>
                <Grid container spacing={2} justifyContent="center" style={{paddingBottom:'10px'}}>
                  <Grid item>
                    <FormControl style={{width: '200px'}}>
                      <InputLabel id="region-label">Metric</InputLabel>
                      <Select
                        labelId="region-label"
                        id="selectedmetric"
                        value={selectedMetric}
                        onChange={handleSelectedMetricChange}
                        label="Metric"
                      >
                        <MenuItem value={'studentTalkPercentage'}>Student Talk Percentage</MenuItem>
                        <MenuItem value={'longWaitTimes'}>Long Wait Times</MenuItem>
                        <MenuItem value={'shortWaitTimes'}>Short Wait Times</MenuItem>                  
                        <MenuItem value={'multiWordStudentUtterances'}>Multi-word Student Utterances</MenuItem>
                        <MenuItem value={'singleWordStudentUtterances'}>Single-word Student Utterances</MenuItem>
                        <MenuItem value={'sessionTime'}>Session Time</MenuItem>
                        <MenuItem value={'studentMathTermCount'}>Student Math Term Count</MenuItem>
                        <MenuItem value={'studentUtteranceCount'}>Student Utterance Count</MenuItem>
                        <MenuItem value={'totalStudentUtteranceWordCount'}>Total Student Utterance Word Count</MenuItem>
                        <MenuItem value={'tutorMathTermCount'}>Tutor Math Term Count</MenuItem> 
                        <MenuItem value={'tutorTalkTime'}>Tutor Talk Time</MenuItem>
                        <MenuItem value={'tutorUtteranceCount'}>Tutor Utterance Count</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={1} justifyContent="center">
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={e => {handleAveragesViewClick(selectedTutorIds,startDate,endDate,selectedMetric)}}
                      style={{
                        textTransform: "none",
                        borderRadius: "6px",
                        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                        padding: "8px 16px",
                      }}
                      disabled={averagesLoading}
                    >
                      {averagesLoading ? <CircularProgress size={24} /> : "View"}
                    </Button>
                  </Grid>
                </Grid>
                
                <Grid item style={{width: '100%'}}>
                  {averagesData.map((item, index) => (
                    <div key={item.tutorId} className="chart-container">
                      <TutorChart tutorId={item.tutorId} label={item.label} averages={item.averages} metric={chartMetric} sessions={item.sessions}/>
                    </div>
                  ))}
                </Grid>
              </Grid>
            )}
          </div>
        </div>
      }
      
      {/* Sessions Processed Content */}
      {activeMenu === 'Sessions Processed' && 
        <div className="page-wrapper">
          <div className="content-container">
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <h3>Sessions Processed</h3>
              </Grid>
            </Grid>

            {sessionFlag && (
              <div className="error-message">
                <p>Please select a date.</p>
              </div>
            )}

            <Grid container spacing={1} justifyContent="center">
              <Grid item>
                <TextField
                  id="datePicker"
                  label="Select Date"
                  type="date"
                  value={selectedDate}
                  onChange={handleDateChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSessionSendClick}
                  disabled={sessionLoading}
                >
                  {sessionLoading ? <CircularProgress size={24} /> : "View"}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleSessionDownloadClick}
                  disabled={sessionDownload}
                >
                  {sessionDownload ? <CircularProgress size={24} /> : "Download"}
                </Button>
              </Grid>
            </Grid>

            {data.length > 0 && (  
              <div className="chart-wrapper"> 
                <Bar data={data_session} options={options_session} />
              </div>
            )}
          </div>
        </div>
      }
    </div>
  </div>
);
}


export default Dashboard;
