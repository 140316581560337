// SubInnerBranch.js
import React from 'react';
import Flower from './Flower';
import Leaves from './Leaves';

const SubInnerBranch = ({
  id,
  direction,
  type,
  leaves,
  flowerId,
  getFlowerColor,
  shouldShowLeaf,
  shouldShowFlower
}) => {
  return (
    <div
      id={id}
      style={{ display: 'block' }}
      sname="true"
      className={`branch ${direction} ${type}`}
    >
      {shouldShowFlower(flowerId) && <Flower flowerId={flowerId} color={getFlowerColor(flowerId)} />}
      <Leaves leaves={leaves} shouldShowLeaf={shouldShowLeaf} />
    </div>
  );
};

export default SubInnerBranch;
