// InnerBranch.js
import React from 'react';
import Flower from './Flower';
import Leaves from './Leaves';
import SubInnerBranch from './SubInnerBranch';

const InnerBranch = ({
  id,
  direction,
  type,
  leaves,
  flowerId,
  flowerColor,
 
  getFlowerColor,
  subBranch,
  shouldShowLeaf,
  shouldShowFlower,
  shouldShowBranch
}) => {
  return (
    <div
      id={id}
      style={{ display: 'block' }}
      className={`branch ${direction} ${type}`}
    >
      {shouldShowFlower(flowerId) && <Flower flowerId={flowerId} color={getFlowerColor(flowerId)} />}
      <Leaves leaves={leaves} shouldShowLeaf={shouldShowLeaf}  />

      {subBranch && shouldShowBranch(subBranch.id) && (
        <SubInnerBranch
          {...subBranch}
          shouldShowLeaf={shouldShowLeaf}
          shouldShowFlower={shouldShowFlower}
          flowerColor={flowerColor}
          getFlowerColor={getFlowerColor}
          
        />
      )}
    </div>
  );
};

export default InnerBranch;