import React, { useEffect, useState } from 'react';
// Material-UI imports
import { 
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Accordion,
  CircularProgress
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getTimeZoneForRegion } from '../Tree/TimezoneUtility'; // Import our timezone utility functions

const AccordionComponent = ({ 
  inputType, 
  setInputType, 
  transcriptSessionID, 
  setTranscriptSessionID, 
  handleSessionIdChange, 
  handleDataRetrievalClick,
  selectedRegion,
  handleselectedRegionChange,
  regionList,
  tutorList,
  setTutorList,
  setSelectedTutorId,
  handleDateChange,
  handleTutorChange,
  selectedTutorId,
  selectedDate,
  availablePeriods,
  selectedPeriod,
  handlePeriodChange,
  fetchAvailablePeriods,
}) => {
  // Add state for the timezone
  const [currentTimezone, setCurrentTimezone] = useState("America/Chicago"); // Default timezone
  // Add loading state for periods
  const [isLoadingPeriods, setIsLoadingPeriods] = useState(false);
  // State to track if the period dropdown is open
  const [isPeriodDropdownOpen, setIsPeriodDropdownOpen] = useState(false);

  // Update timezone when region changes
  useEffect(() => {
    if (selectedRegion) {
      const newTimezone = getTimeZoneForRegion(selectedRegion);
      setCurrentTimezone(newTimezone);
    }
  }, [selectedRegion]);

  // Fetch available periods when tutor or date changes
  useEffect(() => {
    if (selectedTutorId && selectedDate) {
      setIsLoadingPeriods(true);
      fetchAvailablePeriods().finally(() => {
        setIsLoadingPeriods(false);
      });
    }
  }, [selectedTutorId, selectedDate]);

  // Create a modified tutor change handler that resets date and period
  const handleTutorChangeWithReset = (e) => {
    handleTutorChange(e);
    const emptyEvent = { target: { value: "" } };
    handleDateChange(emptyEvent);
    handlePeriodChange(emptyEvent);
  };

  // Create a modified region change handler that resets tutor, date and period
  const handleRegionChangeWithReset = (e) => {
    handleselectedRegionChange(e);
    
    // Clear tutor list and set loading state
    setTutorList([]);
    setSelectedTutorId('');

    // Reset tutor, date and period to null/empty
    const emptyEvent = { target: { value: "" } };
    handleTutorChange(emptyEvent);
    handleDateChange(emptyEvent);
    handlePeriodChange(emptyEvent);
  };

  // Enhanced date change handler with timezone consideration
  const handleDateChangeWithTimezone = (e) => {
    handleDateChange(e);
    
    // If we need additional timezone conversion logic for the date,
    // we can add it here
  };

  // Format time display with timezone
  const formatTimeWithTimezone = (timestamp) => {
    if (!timestamp) return '';
    
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: currentTimezone
    });
  };

// Add this useEffect in your AccordionComponent
useEffect(() => {
  // If transcriptSessionID is set from period selection, trigger data retrieval
  if (transcriptSessionID && transcriptSessionID.length > 0 && selectedPeriod) {
    handleDataRetrievalClick();
  }
}, [transcriptSessionID, selectedPeriod]);

// Then simplify your handlePeriodChangeWithSessionUpdate
const handlePeriodChangeWithSessionUpdate = (e) => {
  const selectedValue = e.target.value;
  handlePeriodChange(e);
  
  if (selectedValue) {
    const selectedPeriodObj = availablePeriods.find(p => p.id === selectedValue);
    if (selectedPeriodObj && selectedPeriodObj.sessionId) {
      setTranscriptSessionID(selectedPeriodObj.sessionId);
    }
  }
};

  // Handlers for period dropdown open/close
  const handlePeriodDropdownOpen = () => {
    setIsPeriodDropdownOpen(true);
    // If we have a tutor and date but no periods loaded yet, start loading
    if (selectedTutorId && selectedDate && availablePeriods.length === 0 && !isLoadingPeriods) {
      setIsLoadingPeriods(true);
      fetchAvailablePeriods().finally(() => {
        setIsLoadingPeriods(false);
      });
    }
  };

  const handlePeriodDropdownClose = () => {
    setIsPeriodDropdownOpen(false);
  };

   // Modify the renderValue for the period Select to handle different scenarios
   const renderPeriodValue = (selected) => {
    // If periods are loading
    if (isLoadingPeriods) {
      return 'Loading periods...';
    }

    // If no periods are available
    if (availablePeriods.length === 0) {
      return 'No periods available';
    }

    // If a period is selected
    const period = availablePeriods.find(p => p.id === selected);
    if (period) {
      const timeString = formatTimeWithTimezone(period.startTime);
      return `${timeString} - ${period.title}`;
    }

    // Default case: prompt to select a period
    return '-- Select Period --';
  };

  return (
    <div style={{
      borderRadius: "8px",
      boxShadow: "0 2px 10px rgba(0,0,0,0.05)",
      marginBottom: "10px",
    }}>
      
      <Accordion style={{ height: "20%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Enter session ID: {transcriptSessionID}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <FormControl style={{width: '220px'}}>
                <InputLabel id="region-label">Select School Year</InputLabel>
                <Select
                  labelId="input-label"
                  id="selectedinputtype"
                  value={inputType}
                  onChange={(e) => setInputType(e.target.value)}
                  label="Select School Year"
                >
                  <MenuItem value={'2023-fall-standard'}>2023-2024</MenuItem>
                  <MenuItem value={'2024-fall-standard'}>2024-2025</MenuItem>          
                  <MenuItem value={'2025-TeachFX-sample'}>TeachFX Sample Sessions</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <TextField style={{ width: "400px" }}
                id="transcriptSessionID"
                label="Enter the session ID"
                type="text"
                value={transcriptSessionID}
                onChange={handleSessionIdChange}
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleDataRetrievalClick}
                style={{
                  textTransform: "none",
                  borderRadius: "6px",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  padding: "8px 16px",
                }}
              >
                Retrieve Data 
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

     
    <Accordion defaultExpanded style={{ height: "20%" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Typography>Select by tutor {selectedRegion ? `(${selectedRegion} - ${currentTimezone})` : ''}</Typography>
      </AccordionSummary>
      <AccordionDetails >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <FormControl style={{ width: '200px', marginBottom: '15px' }}>
              <InputLabel id="region-label">Select Region</InputLabel>
              <Select
                labelId="region-label"
                id="selectedRegion"
                value={selectedRegion}
                onChange={handleRegionChangeWithReset} 
                label="Select Region"
              >
                {regionList.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
          <FormControl style={{ width: '300px', marginBottom: '15px' }}>
            <InputLabel id="tutor-select-label">Select Tutor</InputLabel>
            <Select
              labelId="tutor-select-label"
              value={selectedTutorId}
              onChange={handleTutorChangeWithReset} 
              label="Select Tutor"
              renderValue={(selected) => {
                const tutor = tutorList.find(t => t.userId === selected);
                if (!tutor) return '';
                return `${tutor.name} - ${tutor.readableDate}`;
              }}
            >
              {tutorList.map((tutor) => (
                <MenuItem key={tutor.userId} value={tutor.userId} style={{ padding: '8px 16px' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span style={{ fontWeight: 'bold' }}>{tutor.name}</span>
                      {selectedTutorId === tutor.userId && (
                        <span style={{ color: '#1976d2', fontSize: '0.9em' }}>(Selected)</span>
                      )}
                    </div>
                    <span style={{ fontSize: '0.85em', color: '#666', marginTop: '0px' }}>
                      Most recent: {tutor.readableDate}
                      </span>
                    <span style={{ fontSize: '0.85em', color: '#666', marginTop: '0px' }}>
                      Count: {tutor.sessionCount}
                    </span>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          </Grid>
          <Grid item>
            <TextField
              style={{ width: "220px", marginBottom: '15px' }}
              id="start-date"
              label={`Select Date (${selectedRegion ? selectedRegion : 'Local'} Time)`}
              type="date"
              value={selectedDate}
              onChange={handleDateChangeWithTimezone}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item>
      <FormControl style={{ width: '400px', marginBottom: '15px' }}>
        <InputLabel id="period-label">
          {isLoadingPeriods 
            ? 'Loading Periods...' 
            : availablePeriods.length === 0 
              ? 'No Periods Available' 
              : 'Select Period'}
        </InputLabel>
        <Select
          labelId="period-label"
          id="selectedPeriod"
          value={selectedPeriod}
          onChange={handlePeriodChangeWithSessionUpdate}
          onOpen={handlePeriodDropdownOpen}
          onClose={handlePeriodDropdownClose}
          label="Select Period"
          renderValue={renderPeriodValue}
        >
          {isLoadingPeriods ? (
            <MenuItem disabled value="">
              <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
                <CircularProgress size={20} style={{ marginRight: '10px' }} />
                <span>Loading periods...</span>
              </div>
            </MenuItem>
          ) : availablePeriods.length > 0 ? (
            availablePeriods.map((period) => {
              // Format the time based on the selected region's timezone
              const timeString = formatTimeWithTimezone(period.startTime);
              
              return (
                <MenuItem key={period.id} value={period.id} style={{ padding: '8px 16px' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span style={{ fontWeight: 'bold' }}>{timeString}</span>
                      {selectedPeriod === period.id && (
                        <span style={{ color: '#1976d2', fontSize: '0.9em' }}>(Selected)</span>
                      )}
                    </div>
                    <span style={{ color: '#444', marginTop: '4px' }}>{period.title}</span>
                    {period.students && period.students.length > 0 && (
                      <span style={{ fontSize: '0.85em', color: '#666', marginTop: '4px' }}>
                        Students: {period.students.join(', ')}
                      </span>
                    )}
                  </div>
                </MenuItem>
              );
            })
          ) : (
            <MenuItem disabled value="">
              No periods available
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </Grid>
          {/* <Grid item>
            <p>Data will load once you choose a period.</p>
          </Grid> */}
        </Grid>
      </AccordionDetails>
    </Accordion>
    </div>
  );
};

export default AccordionComponent;