/**
 * Renders a chart component for displaying tutor averages on a user-selected metric.
 *
 * @param {Object} tutorid - The tutor ID object.
 * @param {string} tutorid.tutorId - The ID of the tutor.
 * @param {Array} tutorid.sessions - The sessions data for the tutor.
 * @param {Array} tutorid.averages - The average data for the tutor.
 * @param {string} tutorid.metric - The metric type for the tutor.
 * @param {Array} tutorid.label - The labels for the chart.
 * @returns {JSX.Element} The rendered TutorChart component.
 */

import React from "react";
import "./styles.css";
import "./style.css";

import { Scatter  } from "react-chartjs-2";
import annotationPlugin from 'chartjs-plugin-annotation';
import 'chartjs-adapter-date-fns';

import{
  Chart as ChartJS,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  Filler,
  TimeScale
} from 'chart.js';

 ChartJS.register(
  LineElement,
  BarElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  Filler,
  annotationPlugin,
  TimeScale
);

const TutorChart = (tutorid) =>{

  let sessions = tutorid.sessions;
  let yaxislabel = "";
  let averages = tutorid.averages;

  // if averages is undefined, set it to an empty array
  if (!averages) { averages = []; }
  // same with sessions
  if (!sessions) { sessions = []; }

  if(tutorid.metric === "singleWordStudentUtterances" || tutorid.metric === 'multiWordStudentUtterances' || tutorid.metric === "studentUtteranceCount" || tutorid.metric === "tutorUtteranceCount"){
    yaxislabel = "Utterances";
  }
  else if(tutorid.metric === "studentMathTermCount" || tutorid.metric === "tutorMathTermCount"){
    yaxislabel = "Terms";
  }
  else if(tutorid.metric === "totalStudentUtteranceWordCount"){
    yaxislabel = "Words";
  }
  else if(tutorid.metric === "sessionTime" || tutorid.metric === "tutorTalkTime"){
    yaxislabel = "Time (minutes)";
    averages = averages.map(value => Math.round(value / 60));
  }
  else if (tutorid.metric === "studentTalkPercentage") {
    yaxislabel = "Student Talk %"
  }
  else{
    yaxislabel = "Count";
  }

  const data = {
    labels: tutorid.label,
    datasets: [
      {
        label: tutorid.metric,
        data: averages,
        backgroundColor: "rgba(18, 195, 239, 0.2)",
        borderColor: "rgba(65,105,225,1)",
        pointRadius: 10,
        pointHoverRadius: 10,
      },
      ]
  };

  const options = {
    responsive: true,
    width: 30, 
    height: 600,
      title: {
        display: true,
        text: "Talk ratio : By total time talking"
      },
      scales: {
        y:
          {
            min: 0,
            ticks: {
              display: true,
          },
            title:{
              display: true,
              text: yaxislabel,
            },
          },
        x:
          {
            type: 'time',
            time: {
                unit: 'day',
                tooltipFormat: 'dd/MM/yyyy',
              },

            
  
          },
      },
      plugins: {
        tooltip: {
          callbacks: {
            title: (tooltipItems) => {
              // Format the date to a more readable format
              const date = new Date(tooltipItems[0].label);
              return date.toLocaleDateString();
            },
            label: (tooltipItem) => {
              const index = tooltipItem.dataIndex;
              const sessionsCount = sessions[index];
              return `Sessions: ${sessionsCount}`
            },
            afterLabel: (tooltipItem) => {
              const index = tooltipItem.dataIndex;
              return `Average: ${averages[index]}`;
            }
  
          }
        }
      }
    
  }
 
  // When the tutor has no data to display, show a message instead of the graph
  const isEmptyData =  averages.length === 0 && sessions.length === 0;
  const graphStyle = isEmptyData
    ? { width: '800px', height: '100px', marginBottom: "10px" }
    : { width: '800px', height: '650px', marginBottom: "-180px" };
 
return (
  <div className="graph-portion">
    <div className="graph" style={graphStyle}>
    <b>Tutor ID: {tutorid.tutorId}</b>
    {isEmptyData ? (
        <div style={{color: "red"}}>
          <p>There is no data for this tutor</p>
        </div>
        ) : (
          <Scatter data={data} options={options} />
        )}
    </div>
  </div>
);
}

export default TutorChart;