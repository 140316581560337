import { useEffect, useState } from "react";
import Tree from "./Tree/Tree";

const SessionTrees = ({
  processTranscriptData,
  analyticsSessionID,
  previousSessionIds,
  retrieveSagaUserRegion,
  inputType,
  region
}) => {
  const [sessionsData, setSessionsData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [expandedAccordions, setExpandedAccordions] = useState({});
  
  const styles = {
    sessionItem: {
      margin: '10px',
      padding: '10px',
      border: '2px solid #ccc',
      borderRadius: '10px',
      backgroundColor: '#f9f9f9',
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
      transition: 'all 0.3s ease',
    },
    currentSessionItem: {
      border: '5px solid #4a90e2', 
    },
    sessionItemCollapsed: {
      minWidth: '350px', 
      width: 'auto',
      height: '50px',  
      overflow: 'hidden' 
    },
    sessionItemExpanded: {
      minWidth: '350px',
      height: 'auto',  
    },
    sessionHeader: {
      fontFamily: 'sans-serif',
      fontSize: '1.5rem',
      color: '#333',
      marginBottom: '10px',
      textAlign: 'center',
    },
    sessionDate: {
      display: 'inline-block',
      fontFamily: 'Arial, sans-serif',
      fontSize: '1rem',
      color: '#000',
      padding: '5px 10px',
      backgroundColor: '#e0e0e0',
      borderRadius: '5px',
      marginTop: '0', 
    },
    accordionHeader: {
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '5px 0',  
      whiteSpace: 'nowrap',
      height: '30px',  
      width: '100%',   
    },
    accordionIcon: {
      marginLeft: '10px',
      transition: 'transform 0.3s ease',
    },
    accordionContent: {
      overflow: 'hidden',
      transition: 'max-height 0.3s ease, margin 0.3s ease, padding 0.3s ease',
      maxHeight: 0,
      margin: 0,
      padding: 0,
    },
    accordionContentExpanded: {
      maxHeight: '2000px',
      marginTop: '10px',
      opacity: 1,
      visibility: 'visible',
    },
    accordionContentCollapsed: {
      maxHeight: '0',
      marginTop: '0',
      opacity: 0,
      visibility: 'hidden',
      padding: '0',
    }
  };
  
  useEffect(() => {
    const fetchAllSessionsData = async () => {
      setIsLoading(true);
      setError(null);
      
      const allSessionIds = [analyticsSessionID, ...previousSessionIds].filter(Boolean);
      
      try {
        // Create an object to store all sessions data
        const sessionsDataObj = {};
        
        // Process each session one by one
        for (const sessionId of allSessionIds) {
          if (sessionId) { // Skip null sessionIds (days with no session)
            const data = await processTranscriptData(sessionId, inputType);
            if (data) {
              sessionsDataObj[sessionId] = data;
            }
          }
        }
        
        setSessionsData(sessionsDataObj);
        
        // Set all sessions to be expanded by default
        const initialExpandedState = {};
        allSessionIds.forEach(id => {
          if (id) {
            initialExpandedState[id] = true;
          }
        });
        setExpandedAccordions(initialExpandedState);
      } catch (err) {
        setError("Error fetching sessions data: " + err.message);
        console.error("Error fetching sessions data:", err);
      } finally {
        setIsLoading(false);
      }
    };
    
    if (analyticsSessionID || (previousSessionIds && previousSessionIds.some(id => id !== null))) {
      fetchAllSessionsData();
    }
  }, [analyticsSessionID, previousSessionIds, processTranscriptData, inputType]);
  
  const toggleAccordion = (sessionId) => {
    setExpandedAccordions(prev => ({
      ...prev,
      [sessionId]: !prev[sessionId]
    }));
  };
  
  if (isLoading) {
    return <div>Loading sessions data...</div>;
  }
  
  if (error) {
    return <div className="error-message">{error}</div>;
  }

  // Format date for display
  const formatDisplayDate = (date) => {
    if (!date) return '';
    const options = { weekday: 'long', month: 'short', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  return (
    <div className="all-sessions-container">
      <div className="sessions-row">
        {/* Current Session */}
        {analyticsSessionID && (
          <div className="accordion-item" style={{
            ...styles.sessionItem,
            ...styles.currentSessionItem, 
            ...(expandedAccordions[analyticsSessionID] ? styles.sessionItemExpanded : styles.sessionItemCollapsed)
          }}>
            <div 
              style={styles.accordionHeader}
              onClick={() => toggleAccordion(analyticsSessionID)}
            >
              <span style={styles.sessionDate}>
                {formatDisplayDate(new Date(sessionsData[analyticsSessionID]?.sessionMetadata?.startTime))}
              </span>
              <span 
                style={{
                  ...styles.accordionIcon,
                  transform: expandedAccordions[analyticsSessionID] ? 'rotate(180deg)' : 'rotate(0deg)'
                }}
              >
                ▼
              </span>
            </div>
            
            <div 
              style={{
                ...styles.accordionContent,
                ...(expandedAccordions[analyticsSessionID] 
                  ? styles.accordionContentExpanded 
                  : styles.accordionContentCollapsed)
              }}
            >
              <Tree
                processTranscriptData={processTranscriptData}
                sessionId={analyticsSessionID}
                retrieveSagaUserRegion={retrieveSagaUserRegion}
                yearInputType={inputType}
                region={region}
              />
            </div>
          </div>
        )}
        
        {/* Previous Sessions */}
        {previousSessionIds && previousSessionIds.length > 0 && (
          previousSessionIds.map((sessionId, index) => (
            sessionId &&
            <div 
              key={sessionId || `no-session-${index}`} 
              className="accordion-item"
              style={{
                ...styles.sessionItem,
                ...(expandedAccordions[sessionId] ? styles.sessionItemExpanded : styles.sessionItemCollapsed)
              }}
            >
              <div 
                style={styles.accordionHeader}
                onClick={() => toggleAccordion(sessionId)}
              >
                <span style={styles.sessionDate}>
                  {formatDisplayDate(new Date(sessionsData[sessionId]?.sessionMetadata?.startTime))}
                </span>
                <span 
                  style={{
                    ...styles.accordionIcon,
                    transform: expandedAccordions[sessionId] ? 'rotate(180deg)' : 'rotate(0deg)'
                  }}
                >
                  ▼
                </span>
              </div>
              
              <div 
                style={{
                  ...styles.accordionContent,
                  ...(expandedAccordions[sessionId] 
                    ? styles.accordionContentExpanded 
                    : styles.accordionContentCollapsed)
                }}
              >
                <Tree
                  processTranscriptData={processTranscriptData}
                  sessionId={sessionId}
                  retrieveSagaUserRegion={retrieveSagaUserRegion}
                  yearInputType={inputType}
                  region={region}
                />
              </div>
            </div>
          ))
        )}
      </div>
      
      {/* CSS for horizontal layout */}
      <style jsx>{`
        .sessions-row {
          display: flex;
          flex-direction: row;
          overflow-x: auto;
          gap: 5px;
          padding-bottom: 20px;
          /*flex-wrap: wrap;*/
          flex-wrap: no-wrap;
          /*justify-content: space-evenly;*/
          justify-content: flex-start;
        }
        
        .accordion-item {
          transition: height 0.3s ease, width 0.3s ease;
        }
      `}</style>
    </div>
  );
};

export default SessionTrees;
