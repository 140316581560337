import {Button} from '@mui/material';
const HorizontalNavbar = ({activeMenu, setActiveMenu, setActiveVisualization, handleLogout })=> {
    return (
<div
  style={{
    backgroundColor: "#ffffff",
    boxShadow: "0 2px 10px rgba(0,0,0,0.08)",
    padding: "12px 24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "sticky",
    top: 0,
    zIndex: 1000,
    flexWrap: "wrap", // Allow items to wrap on smaller screens
  }}
>
      {/* Logo container with better positioning */}
      <div style={{  alignItems: "center", justifyContent: "center", display: "flex" }}>
    <img
      src={`${process.env.PUBLIC_URL}/LEVI-HAT.jpeg`}
      alt="LEVI-HAT Logo"
      style={{
        height: "50px",
        width: "auto",
        marginRight: "15px",

      }}
    />
  </div>
      
      {/* Center - Navigation Items with improved styling */}
      <div     style={{
      display: "flex",
      flexWrap: "wrap", // Allow items to wrap on smaller screens
      gap: "8px",
      borderRadius: "8px",
      backgroundColor: "#f5f7fa",
      padding: "4px",
    }}
  >
        <div
          onClick={()=>setActiveMenu('Session-level Visualizations')}
          style={{
            padding: "10px 16px",
            cursor: "pointer",
            color: activeMenu === 'Session-level Visualizations' ? "white" : "#5a6776",
            backgroundColor: activeMenu === 'Session-level Visualizations' ? "#4b5563" : "transparent",
            borderRadius: "6px",
            transition: "all 0.2s ease",
            fontWeight: activeMenu === 'Session-level Visualizations' ? "600" : "500",
            boxShadow: activeMenu === 'Session-level Visualizations' ? "0 2px 4px rgba(0,0,0,0.15)" : "none",
            fontSize: "15px"
          }}>
          Session-level Visualizations
        </div>
        <div 
          onClick={() => {
            setActiveMenu('Tutor Averages');
            setActiveVisualization(null);
          }}
          style={{
            padding: "10px 16px",
            cursor: "pointer",
            color: activeMenu === 'Tutor Averages' ? "white" : "#5a6776",
            backgroundColor: activeMenu === 'Tutor Averages' ? "#4b5563" : "transparent",
            borderRadius: "6px",
            transition: "all 0.2s ease",
            fontWeight: activeMenu === 'Tutor Averages' ? "600" : "500",
            boxShadow: activeMenu === 'Tutor Averages' ? "0 2px 4px rgba(0,0,0,0.15)" : "none",
            fontSize: "15px"
          }}>
          Tutor Averages
        </div>
        <div 
          onClick={() => {
            setActiveMenu('Sessions Processed');
            setActiveVisualization(null);
          }}
          style={{
            padding: "10px 16px",
            cursor: "pointer",
            color: activeMenu === 'Sessions Processed' ? "white" : "#5a6776",
            backgroundColor: activeMenu === 'Sessions Processed' ? "#4b5563" : "transparent",
            borderRadius: "6px",
            transition: "all 0.2s ease",
            fontWeight: activeMenu === 'Sessions Processed' ? "600" : "500",
            boxShadow: activeMenu === 'Sessions Processed' ? "0 2px 4px rgba(0,0,0,0.15)" : "none",
            fontSize: "15px",
          }}>          
          Sessions Processed
        </div>
      </div>
      
      {/* Right side - Logout Button with enhanced styling */}
      <Button
  variant="contained"
  color="primary"
  onClick={handleLogout}
  className="responsive-button"
  style={{
    textTransform: "none",
    borderRadius: "6px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    padding: "8px 16px",
  }}
>
  Logout
</Button>

    </div>
    )
}

export default HorizontalNavbar;