
import React, { useEffect, useState } from 'react';

// Timezone mapping
const TZ_MAP = {
  "BAL": "America/New_York",
  "CHI": "America/Chicago",
  "CHS": "America/New_York",
  "DC": "America/New_York",
  "NYC": "America/New_York",
  "OK": "America/Chicago",
  "N/A": "America/New_York",
  "Widespread": "America/Chicago"
};


export const getTimeZoneForRegion = (region) => {
  return TZ_MAP[region] || "America/Chicago"; // Default to Chicago if region not found
};


export const convertTimestampToUserLocalTime = (timestampMs, timeZone) => {
  const date = new Date(timestampMs);
  
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: timeZone
  };

  const formatter = new Intl.DateTimeFormat('en-US', options);
  const formattedDate = formatter.format(date);
  
  // Split into date and time parts
  const parts = formattedDate.split(', ');
  const weekday = parts[0];
  const datePart = parts[1];
  const timePart = parts[2];
  
  return {
    fullDate: `${weekday}, ${datePart}`,
    time: timePart
  };
};


export const calculateDurationInMinutes = (startTime, endTime) => {
  return Math.round((endTime - startTime) / (1000 * 60));
};

export const LocalizedTimestamp = ({ timestamp, region, label }) => {
  const timeZone = getTimeZoneForRegion(region);
  const { time } = convertTimestampToUserLocalTime(timestamp, timeZone);
  
  return (
    <span>
      {label && <strong>{label}: </strong>}
      {time}
      
    </span>
  );
};

/**
 * React component to display a duration
 */
export const DurationDisplay = ({ startTime, endTime, label }) => {
  const duration = calculateDurationInMinutes(startTime, endTime);
  
  return (
    <span>
      {label && <strong>{label}: </strong>}
      {duration} minutes
    </span>
  );
};

/**
 * Container component for session time information
 */


export const SessionTimeInfo = ({ 
  sessionMetadata, 
  region, 
  retrieveSagaUserRegion 
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [processedData, setProcessedData] = useState(null);
  const [resolvedRegion, setResolvedRegion] = useState(region);

  useEffect(() => {
    const fetchRegionAndProcessTime = async () => {
      setIsLoading(true);
      setProcessedData(null);

      if (!sessionMetadata) {
        setIsLoading(false);
        return;
      }

      try {
        // If no region is provided, try to retrieve it
        const tutorId = sessionMetadata.tutorId || 
          sessionMetadata.participants?.find(p => p.type === 'tutor')?.id;

        // If no region is provided, try to retrieve it
        let finalRegion = resolvedRegion;
        if (!finalRegion && tutorId && retrieveSagaUserRegion) {
          const fetchedRegion = await retrieveSagaUserRegion(tutorId);
          if (fetchedRegion) {
            finalRegion = fetchedRegion;
            setResolvedRegion(fetchedRegion);
          }
        }

        // If we still don't have a region, use a default or a region from the first participant
        if (!finalRegion) {
          finalRegion = 'America/Chicago'; // Default region from your timezone map
        }
        const timeZone = getTimeZoneForRegion(finalRegion);
        const { startTime, endTime } = sessionMetadata;
        
        if (startTime && endTime && timeZone) {
          const formattedStart = convertTimestampToUserLocalTime(startTime, timeZone);
          const duration = calculateDurationInMinutes(startTime, endTime);
          
          setProcessedData({
            startTime,
            endTime,
            formattedStart,
            duration,
            timeZone,
            region: finalRegion
          });
        }
        
        setIsLoading(false);
      } catch (error) {
        console.error("Error processing time data:", error);
        setIsLoading(false);
      }
    };

    fetchRegionAndProcessTime();
  }, [sessionMetadata, region, retrieveSagaUserRegion]);
  
  // If loading or we don't have processed data, show loading
  if (isLoading || !processedData) {
    return (
      <div className="session-time-info">
        <p><strong>Loading ...</strong></p>
      </div>
    );
  }
  
  const { startTime, endTime, region: displayRegion } = processedData;
  
  return (
    <div className="session-time-info">
      <p>
        <LocalizedTimestamp 
          timestamp={startTime} 
          region={displayRegion} 
          label="Start Time" 
        />
      </p>
      <p>
        <DurationDisplay 
          startTime={startTime} 
          endTime={endTime} 
          label="Duration" 
        />
      </p>
    </div>
  );
};



export const getTimestampRangeForDate = (dateString, region) => {
  
  // Create date objects with the timezone consideration
  const startDateStr = `${dateString}T00:00:00`;
  const endDateStr = `${dateString}T23:59:59.999`;
  
  // Convert to Date objects
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);
  
  return {
    startTime: startDate.getTime(),
    endTime: endDate.getTime()
  };
};


export const formatTimestampInRegionTimezone = (timestampMs, region, options = {}) => {
  const timezone = getTimeZoneForRegion(region);
  
  const defaultOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZone: timezone
  };
  
  const mergedOptions = { ...defaultOptions, ...options };
  
  const date = new Date(timestampMs);
  return date.toLocaleTimeString('en-US', mergedOptions);
};

export default {
  getTimeZoneForRegion,
  convertTimestampToUserLocalTime,
  calculateDurationInMinutes,
  LocalizedTimestamp,
  DurationDisplay,
  SessionTimeInfo
};